<template>
    <div class="block block-text" >
        <div class="cap-padded-2x">
            <div class="rte no-max-width">
                <h2 v-if="json.subtitle" v-html="json.subtitle"></h2>
                <div class="under-title" v-if="json.body" v-html="json.body"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "GenericText",
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
    },

}
</script>
