<template>
    <button v-sr :class="`button-back ${extraClasses}`"  @mouseover="triggerButtonEnter" @mouseleave="triggerButtonExit">
        <router-link :to="href">
            <lottie-animation ref="backtonews__button" trigger = "function" :path="require('@/assets/lottie/back-to-news-button.json')" />
        </router-link>
    </button>
</template>

<script>
export default {
    name: "ButtonBack",
    props: {
        href: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        extraClasses: {
            type: String,
            required: false,
            default:''
        },
    },
    methods: {
        triggerButtonEnter() {
            this.$refs['backtonews__button'].playAnimation(1);
        },
        triggerButtonExit() {
            this.$refs['backtonews__button'].playAnimation(-1)
        },
    }
}
</script>
