<template>
    <div class="block block-article-masthead">
        <div class="featured" >
            <img :src="json.bg"/>
        </div>
        <div class="copy">
            <div class="cap-margin-padded-2x">
                <div :class="['share-strip',(relatedArticleCount) ? 'long' : 'short']">
                    <div class="vert" >
                        <div class="inner">
                            <div class="vert-title">SHARE</div>
                            <div class="icons">
                                <ul>
                                    <li>
                                        <a title="Facebook" :href="gh.getFacebookUrl(window.location.href)" target="_blank">
                                            <span class="socicon socicon-facebook"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Twitter" :href="gh.getTwitterUrl(window.location.href, document.title)" target="_blank">
                                            <span class="socicon socicon-twitter"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a title="LinkedIn" :href="gh.getLinkedInUrl(window.location.href)" target="_blank">
                                            <span class="socicon socicon-linkedin"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-if="relatedArticleCount" @click="gotoRelated" class="circle" >
                        <div class="circle2"></div>
                        <div class="copy">
                            <div class="copy2">
                                <div class="l1">RELATED<br>ARTICLES</div>
                                <div class="l2">{{ relatedArticleCount }}</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="circle-black" >
                    <fuzzy-circle ref = "masthead_circle" type="big-black"/>
                    <div v-show="json.author" class="txt" ><span>BY {{json.author}}</span></div>
                    <div class="circle-gray" >
                        <fuzzy-circle ref = "small_circle" type="small-gray"/>
                        <div class="date1">
                            <div class="date2" v-html="gh.dateToMONDDYYYY(json.dateNews)"></div>
                        </div>
                    </div>
                </div>
                <div class="title">
                    <h1 v-html="json.title"></h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
export default {
    name: "ArticleMast",
    components: {

    },
    data () {
        return {
            document:document,
            window:window,
            gh:gh,
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.masthead_circle.playAnimation();
        });
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
        relatedArticleCount: {
            type:Number,
            required:true
        }
    },
    methods: {
        gotoRelated() {
            gh.scrollTo('#related-articles', window)
        },
    }
}
</script>
