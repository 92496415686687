<template>
    <div class = "tnm__slider two" v-sr="{ beforeReveal: triggerAnimations, distance: 0 }">
        <div ref = "holder">
            <div class = "tnm__slider__header" ref = "header">
                <div class = "tnm__slider__animation-holder tnm__slider__title">
                    <div class = "tnm__slider__animation-holder__animation tnm__fuzzy-circle black" ref = "large__circle">
                        <fuzzy-circle ref = "large__circle_inner" type="big-black"/>
                        <div class = "tnm__small-circle bottom left transparent gray" ref = "small__circle">
                            <fuzzy-circle ref = "small__circle_inner" type="small-gray"/>
                        </div>
                        <div class = "tnm__overlay right bottom" ref = "circle__overlay">
                            <lottie-animation trigger = "function" ref = "overlay" :path="require('@/assets/lottie/header-sphere-darker.json')" />
                        </div>
                        <h2 class = "tnm__text" ref = "title">
                            {{ block.title }}
                        </h2>
                    </div>
                </div>
            </div>
            <slider-image :slides="block.slides" :activeSlide="activeSlide"></slider-image>
            <div class = "tnm__graybg tnm__slider__body" ref = "body">
                <div class = "tnm__slider__body__inner tnm__graybg light">
                    <div class = "swiper-container" ref = "slider">
                        <div class = "swiper-wrapper">
                            <slide v-for="(item, index) in block.slides" :key="`item-${index}`" :item="item" :num="index" :timing="650" :activeSlide="activeSlide"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class = "tnm__slider__locations" ref = "locations">
                <div v-for="(item, index) in block.locations" :key="`item-${index}`" class="tnm__slider__locations__location">
                    <h3 :class="(item == block.slides[activeSlide].location ? 'active' : '')">{{ item }}<span class = "tnm__rule__vertical tnm__rule"></span></h3>
                </div>
            </div>
            <controls :activeSlide="activeSlide" :numSlides="block.slides.length" ref = "controls" />
        </div>
    </div>
</template>
<script>
import Slide from '@/components/pieces/Slide';
import SliderImage from '@/components/pieces/SliderImage';
import Controls from '@/components/pieces/slider/Controls';

import { SplitText } from '@/assets/js/gsap/SplitText';
import gsap from '@/assets/js/gsap/gsap-core';
import Swiper, {
    Navigation,
    Pagination,
    Autoplay
} from 'swiper'
import 'swiper/swiper-bundle.min.css'
Swiper.use([Navigation, Pagination, Autoplay])

export default {
    name: "SliderTwo",
    components: {
        Slide,
        SliderImage,
        Controls
    },
    data() {
        return {
            activeSlide: 0,
            slider: null,
            resizeTimeout: null,
        }
    },
    props: [
        'block'
    ],
    created () {
    },
    mounted() {
        this.tl = gsap.timeline();
        this.initSlider();
        this.$nextTick(this.splitText);
    },
    updated() {
        this.$nextTick(this.splitText);
    },
    methods: {
        triggerButtonEnter(btn) {
            if(window.innerWidth > 850) {
                if((btn === 'left' && this.activeSlide !== 0) || (btn == 'right' && this.activeSlide + 1 !== this.block.slides.length)) {
                    this.$refs[btn].classList.add('active');
                    this.$refs[btn + '__button'].playAnimation(1);
                }
            }
        },
        triggerButtonExit(btn) {
            if(window.innerWidth > 850) {
                this.$refs[btn].classList.remove('active');
                this.$refs[btn + '__button'].playAnimation(-1);
            }
        },
        splitText() {
            this.splitTitle = new SplitText(this.$refs.title, { type: 'words' });
        },
        triggerAnimations() {
            this.tl
                .from(this.$refs.holder, { duration: 1, opacity: 0, x: -500, ease: "power3", clearProps: "all" }, 0)
                .from(this.$refs.large__circle, { duration: 1, opacity: 0, scale: 0.8, ease: "power3", clearProps: "all" }, 0.3)
                .add(() => { this.$refs.large__circle_inner.playAnimation(); }, '-=1')
                .from(this.$refs.small__circle, { duration: 0.3, opacity: 0, ease: "power3", clearProps: "all" }, '-=.1')
                .add(() => { this.$refs.small__circle_inner.playAnimation(); }, '-=.3')
                .from(this.$refs.circle__overlay, { duration: 1.2, opacity: 0, x: -10, y: -10, rotation: -180, ease: "power3", clearProps: "all" }, 0.6)
                .add(() => { this.$refs.overlay.playAnimation(1); }, 0.6)
                .from(this.splitTitle.words, {duration: 0.7, opacity: 0, x: -100, ease: "power3", stagger: 0.06, letterSpacing: '.5rem'}, 0.5)
                .from(this.$refs.controls, {duration: 0.8, opacity: 0, y: 100, ease: "power3"}, 1)
                .from(this.$refs.locations, {duration: 0.8, opacity: 0, y: 100, ease: "power3"}, 1)
                .from(this.$refs.left, {duration: 0.5, opacity: 0, y: 100, ease: "power3" }, '-=.6')
                .from(this.$refs.right, {duration: 0.5, opacity: 0, y: 100, ease: "power3" }, '-=.3')
                .from(this.$refs.counter, {duration: 0.5, opacity: 0, y: 100, ease: "power3" }, '-=.3')
                .from(this.$refs.active, {duration: 0.5, opacity: 0, y: 100, ease: "power3" }, '-=.3')
                .from(this.$refs.body, {duration: 1, opacity: 0, x: -200, ease: "power3" }, 1.2)
                .add(() => { this.splitTitle.revert(); }, '+=0')
        },
        initSlider() {
            this.slider = new Swiper(this.$refs.slider, {
                loop: true,
                speed: 400,
                draggable: true,
                slidesPerView: 1,
                spaceBetween: 0,
                preventClicks: true,
                slideToClickedSlide: true,
                cssMode: false,
                direction: 'horizontal',
                swipeAngle: false,
                // allowTouchMove: false,
                // preventClicksPropagation: false,
                navigation: {
                    nextEl: this.$refs.controls.$refs.right,
                    prevEl: this.$refs.controls.$refs.left,
                },
            });

            this.slider.on('slideChangeTransitionStart', function() {
                this.rewindIconAnimation();
            }.bind(this))

            this.slider.on('sliderMove', function() {
                this.rewindIconAnimation();
            }.bind(this))

            this.slider.on('slideChangeTransitionEnd', function() {
                this.playIconAnimation();
                this.activeSlide = this.getSlideDataIndex(this.slider);
            }.bind(this))

            // fixes issue where transitioning from home section 1 to section 3 resizes nav and causes size of slider to change which requires an update.
            setTimeout(() => {
                this.slider.update();
            }, 350)

            window.addEventListener('resize', this.onResize);

        },
        onResize() {
            // update slider after it scales and sometimes there's a 1px line visible
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => {
                this.slider.update();
            }, 1000);
        },
        rewindIconAnimation() {
            gsap.to(this.$refs.icon, { opacity: 0, scale: 0, duration: 0.5, ease: 'power3'});
        },
        playIconAnimation() {
            gsap.fromTo(this.$refs.icon, { opacity: 0, scale: 0 }, { duration: 0.5, opacity: 1, scale: 1, ease: 'power3' });
        },
        getSlideDataIndex(swipe){
            var activeIndex = swipe.activeIndex;
            var slidesLen = swipe.slides.length;
            if(swipe.params.loop){
                switch(swipe.activeIndex){
                    case 0:
                        activeIndex = slidesLen-3;
                        break;
                    case slidesLen-1:
                        activeIndex = 0;
                        break;
                    default:
                        --activeIndex;
                }
            }
            return  activeIndex;
        }
    },
    beforeUnmount() {
        this.slider.destroy();
        window.removeEventListener('resize', this.onResize);
    },
}
</script>
