<template>
    <div class="tnm__contactform__form" ref="form">
        <field v-for="(field, key) in form.fields" :key="`field-${key}`" :field="field" :ref="setRefs"/>

        <div class="tnm__contactform__form__field__error" v-show="!valid">
            {{ form.errorMessage }}
        </div>
        <div class="tnm__contactform__form__field__error" v-show="success">
            {{ form.successMessage }}
        </div>
        <div class="tnm__contactform__form__field__error" v-show="!success && generalErrorMessage">
            {{ generalErrorMessage }}
        </div>
        <div v-show="!isSubmitting" class="tnm__contactform__form__submit" @mouseenter="$refs.submit.playAnimation(1)"
             @mouseleave="$refs.submit.playAnimation(-1)" @click="submitForm">
            <lottie-animation ref="submit" trigger="function" :path="require('@/assets/lottie/submit-button.json')"/>
        </div>
    </div>
</template>
<script>
import Field from '@/components/pieces/Field';
import gsap from '@/assets/js/gsap/gsap-core';

export default {
    name: "Form",
    components: {
        Field,
    },
    data () {
        return {
            fields: [],
            valid: true,
            success: false,
            window:window,
            isSubmitting:false,
            generalErrorMessage:false
        }
    },
    props: [
        'form'
    ],
    mounted () {
        this.isSubmitting = false
        this.success = false
        this.generalErrorMessage = ''
        this.fields = []
    },
    beforeUpdate () {

    },
    watch: {
        form: function () {
            gsap.fromTo(this.$refs.form, { opacity: 0, y: -100, }, { duration: 0.5, opacity: 1, y: 0, ease: 'power3' });
        }
    },
    methods: {
        submitForm () {
            this.isSubmitting = true
            this.success = false
            this.generalError = false
            this.valid = true
            let formData = new FormData()
            formData.append('fields', {})
            for (var x = 0; x < this.fields.length; x++) {
                if (this.fields[x].field.type === 'date') {
                    this.fields[x].checkDate()
                } else if (this.fields[x].field.type === 'upload') {
                    this.fields[x].uploadFile()
                } else {
                    this.fields[x].validate()
                }
                if (!this.fields[x].valid) {
                    this.valid = false
                    this.fields[x].error = true
                } else {
                    this.fields[x].error = false
                }

                let val = this.fields[x].value
                if (this.fields[x].field.name === 'country') {
                    val = val.label
                }
                formData.append('fields[' + this.fields[x].field.name + ']', val)
            }

            if (this.valid) {
                this.sendEmail(formData)
            } else {
                this.isSubmitting = false
            }
        },
        setRefs (el) {
            if (el) {
                this.fields.push(el);
            }
        },

        sendEmail (formData) {
            let data = {
                action: 'formie/submissions/submit',
                handle: this.form.id,
                siteId: 1,
            }
            // Add the CSRF Token
            data[this.window.csrfTokenName] = this.window.csrfTokenValue
            // NOTE - "fields" nested property was already handled in the validation above
            for ( let key in data ) {
                if (key !== 'fields') {
                    formData.append(key, data[key])
                }
            }

            let params = {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }
            let t = this
            fetch('/', params ).then(function(response) {
                this.isSubmitting = false
                if (response.ok) {
                    t.success = true
                    t.generalErrorMessage = ''
                } else {
                    t.success = false
                    t.generalErrorMessage = 'An unknown error has occurred.  Please try again later.'
                }

                /*
                response.json().then(function(data) {
                    console.log(data)
                }.bind(t))

                 */
            }.bind(t))
        }
    }
}
</script>
