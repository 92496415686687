<template>
    <div :class="'tnm__careers ' + (block.departments == true ? 'departments' : '')" v-if="jobs" v-sr="{ beforeReveal: triggerAnimations, distance: 0 }" ref = "careers">
        <div class = "tnm__animation-holder tnm__careers__header">
            <div class = "tnm__careers__animation-holder">
                <div class = "tnm__careers__animation-holder__animation tnm__fuzzy-circle gray" ref = "large__circle">
                    <fuzzy-circle ref = "large__circle_inner" type="big-gray"/>
                    <div class = "tnm__small-circle bottom right front black" ref = "small__circle">
                        <fuzzy-circle ref = "small__circle_inner" type="small-black"/>
                        <span class = "tnm__small-circle__count">{{ alljobs }}</span>
                        <span class = "tnm__small-circle__offset-title">Job Openings</span>
                    </div>
                    <div class = "tnm__overlay left bottom">
                        <lottie-animation trigger = "load" :path="require('@/assets/lottie/header-sphere-darker.json')" />
                    </div>
                    <h2 class = "tnm__text">
                        {{ block.title }}
                    </h2>
                </div>
            </div>
        </div>
        <template v-if="block.departments">
            <div class = "tnm__careers__list departments">
                <div class = "tnm__careers__list__department" v-for="(department, index) in departments" :key="`department-${index}`" v-sr>
                    <div class = "tnm__careers__list__department__header">
                        <inline-svg ref = "department__overlay" :src="require('@/assets/images/careers/careers-arrow.svg')"></inline-svg>
                        <h2>{{ department }}</h2>
                    </div>
                    <career v-for="(item, index) in jobs" :department="department" :description="true" :key="`item-${index}`" :item="item" @closeOthers="closeOthers" @reset="otherOpen=false" :otherOpen="otherOpen" />
                </div>
            </div>
        </template>
        <template v-else>
            <div class = "tnm__careers__list">
                <career v-for="(item, index) in jobs" :key="`item-${index}`" :description="false" :item="item" />
            </div>
            <div class = "tnm__careers__more">
                <link-content href="/careers" target="_self">
                    <div class = "tnm__careers__more__button" ref = "more" @mouseover="triggerButtonEnter('more')" @mouseleave="triggerButtonExit('more')">
                        <lottie-animation trigger = "function" ref = "more__button" :path="require('@/assets/lottie/view-all.json')" />
                    </div>
                </link-content>
            </div>
        </template>
    </div>
</template>
<script>
import Career from '@/components/pieces/Career.vue';
import LinkContent from '@/components/pieces/LinkContent';
import gsap from '@/assets/js/gsap/gsap-core';
export default {
    name: "Careers",
    components: {
        Career,
        LinkContent
    },
    data() {
        return {
            jobs: null,
            departments: null,
            otherOpen: false,
            alljobs: 0,
        }
    },
    props: [
        'block'
    ],
    created () {
    },
    mounted () {
        this.alljobs = window.config.greenhouse.jobs.length;
        if(this.block.cap) {
            this.jobs = window.config.greenhouse.jobs.slice(0, this.block.cap - 1);
        } else {
            this.jobs = window.config.greenhouse.jobs;
        }

        if(this.block.departments) {
            var departments = [];

            for(var x in this.jobs) {
                for(var y in this.jobs[x].departments) {
                    if(!departments.includes(this.jobs[x].departments[y].name)) {
                        departments.push(this.jobs[x].departments[y].name);
                    }
                }
            }
            this.departments = departments;
        }
    },
    methods: {
        triggerButtonEnter(btn) {
            this.$refs[btn].classList.add('active');
            this.$refs[btn + '__button'].playAnimation(1);
        },
        triggerButtonExit(btn) {
            this.$refs[btn].classList.remove('active');
            this.$refs[btn + '__button'].playAnimation(-1);
        },
        raiseCap() {
            this.cap = 1000;
        },
        closeOthers() {
            this.otherOpen = true;
        },
        triggerAnimations() {
            this.tl = gsap.timeline();
            this.tl
                .from(this.$refs.careers, { duration: 0.6, opacity: 0, y: 500, ease: "power3", clearProps: "all" }, 0)
                .from(this.$refs.large__circle, { duration: 0.3, opacity: 0, scale: 0.8, ease: "power3", clearProps: "all" }, '-=.3')
                .add(() => { this.$refs.large__circle_inner.playAnimation(); }, '-=.3')
                .from(this.$refs.small__circle, { duration: 0.3, opacity: 0, scale: 0.8, ease: "power3", clearProps: "all" }, '+=0')
                .add(() => { this.$refs.small__circle_inner.playAnimation(); }, '-=.3')
        }
    }
}
</script>
