<template>
    <router-link v-if="!department" :to="`/careers/${item.id}`" >
        <div v-if="!department || (item.departments?.length && department === item.departments[0].name)" :class="'tnm__careers__list__career '  + (department ? 'has-description' : '')" ref = "career" @mouseenter="triggerCareerEnter" @mouseleave="triggerCareerExit" >
            <div class = "tnm__careers__list__career__inner">
                <div class = "tnm__careers__list__career__overlay__mask"></div>
                <div class = "tnm__careers__list__career__title">
                    {{ item.title }}
                </div>
                <div class = "tnm__careers__list__career__team">
                    {{ item.location?.name }}
                </div>
                <div class = "tnm__careers__list__career__overlay">
                    <lottie-animation trigger = "function" ref = "overlay__animation" :path="require('@/assets/lottie/career.json')" />
                </div>
            </div>
            <div class = "tnm__careers__list__career__button tnm__button" ref = "button" @mouseenter="triggerButtonEnter" @mouseleave="triggerButtonExit">
                <div class = "mobile">
                    <inline-svg ref = "button__svg" :src="require('@/assets/images/global/small-btn.svg')"></inline-svg>
                </div>
                <div class = "tablet">
                    <lottie-animation ref = "button__animation" trigger = "function" :path="require('@/assets/lottie/job-detail-button.json')" />
                </div>
            </div>
        </div>
    </router-link>
    <div v-else>
        <div v-if="item.departments?.length && department === item.departments[0].name" :class="'tnm__careers__list__career '  + (department ? 'has-description' : '')" ref = "career" @mouseenter="triggerCareerEnter" @mouseleave="triggerCareerExit" @click="toggleDescription">
            <div class = "tnm__careers__list__career__inner">
                <div class = "tnm__careers__list__career__overlay__mask"></div>
                <div class = "tnm__careers__list__career__title">
                    {{ item.title }}
                </div>
                <div class = "tnm__careers__list__career__team">
                    {{ item.location?.name }}
                </div>
                <div class = "tnm__careers__list__career__overlay">
                    <lottie-animation trigger = "function" ref = "overlay__animation" :path="require('@/assets/lottie/career.json')" />
                </div>
                <div class = "more-button tnm__careers__list__career__button" ref = "more_button">
                    <inline-svg ref = "more_button__svg" :src="require('@/assets/images/global/small-btn.svg')"></inline-svg>
                </div>
            </div>
            <router-link :to="`/careers/${item.id}`">
                <div class = "tnm__careers__list__career__button tnm__button apply" ref = "button" @mouseenter="triggerButtonEnter" @mouseleave="triggerButtonExit">
                    <div class = "mobile">
                        <inline-svg ref="button__svg" :src="require('@/assets/images/careers/mobile-btn.svg')"></inline-svg>
                    </div>
                    <div class = "tablet">
                        <lottie-animation ref = "button__animation" trigger = "function" :path="require('@/assets/lottie/apply-now-button.json')" />
                    </div>
                </div>
            </router-link>
            <div class = "tnm__careers__list__career__description" ref = "description" v-if="item.content" v-html="getDescription(item.content)">
            </div>
        </div>
    </div>
</template>
<script>
import gsap from '@/assets/js/gsap/gsap-core';
export default {
    name: "Career",
    props: ['item', 'department', 'description', 'otherOpen'],
    data() {
        return {
            clicked: false,
            revealed: false,
            open: false,
            mouseOver: false,
        }
    },
    mounted() {

    },
    watch: {
        otherOpen: function(val) {
            if(val && this.open) {
                this.toggleDescription();
            }
        }
    },
    methods: {
        triggerButtonEnter() {
            this.$refs.button.classList.add('active');
            this.$refs.button__animation.playAnimation(1);
        },
        triggerButtonExit() {
            this.$refs.button.classList.remove('active');
            this.$refs.button__animation.playAnimation(-1);
        },
        triggerCareerEnter() {
            if(!this.open) {
                this.$refs.overlay__animation.goToAndPlay(0, true);
                this.$refs.career.classList.add('active');
            }
            this.mouseOver = true;
        },
        triggerCareerExit() {
            if(!this.open) {
                this.$refs.overlay__animation.goToAndStop(0, true);
                this.$refs.career.classList.remove('active');
            }
            this.mouseOver = false;
        },
        toggleDescription() {
            if(!this.revealing && this.department) {
                this.clicked = !this.clicked;
                this.revealing = true;
                if(this.clicked) {
                    this.open = true;
                    this.$emit('closeOthers');
                    this.showDescription();
                } else {
                    this.hideDescription();
                }
            }
        },
        showDescription() {
            var tl = gsap.timeline();
            tl
                .add(() => { this.$refs.more_button.classList.add('clicked'); }, 0)
                .add(() => { this.$refs.description.classList.add('active'); }, '+=0')
                .add(() => { this.$refs.career.classList.add('expanded'); }, '+=0')
                .fromTo(this.$refs.description, { y: -100, opacity: 0, }, { duration: 0.2, y: 0, opacity: 1, ease: "power3" }, 0)
                .from(this.$refs.description, { duration: 0.2, margin: 0, height: 0, ease: "power3" }, 0)
                .add(() => { this.$refs.button.classList.add('clicked'); }, '+=0')
                .fromTo(this.$refs.button, { y: -100, opacity: 0, }, { duration: 0.2, y: 0, opacity: 1, ease: "power3" }, '+=0')
                .add(() => { this.revealing = false; this.$emit('reset'); }, '+=0')
        },
        hideDescription() {
            var tl = gsap.timeline();
            tl
                .add(() => { this.$refs.more_button.classList.remove('clicked'); })
                .fromTo(this.$refs.description, { y: 0, opacity: 1, }, { duration: 0.2, y: -100, opacity: 0, ease: "power3" }, 0).fromTo(this.$refs.button, { y: 0, opacity: 1, }, { duration: 0.2, y: -100, opacity: 0, ease: "power3" }, 0)
                .to(this.$refs.description, { duration: 0.2, margin: 0, height: 0, ease: "power3" }, 0)
                .add(() => { this.$refs.description.classList.remove('active'); }, '+=0')
                .add(() => { this.$refs.career.classList.remove('expanded'); }, '+=0')
                .add(() => { this.$refs.button.classList.remove('clicked'); }, '+=0')
                .add(() => { if(!this.mouseOver) { this.$refs.career.classList.remove('active'); } }, '+=0')
                .add(() => { this.open = false; }, '+=0')
                .to(this.$refs.description, { clearProps:"all" }, '+=0')
                .add(() => { this.revealing = false; this.$emit('reset'); }, '+=0')
        },
        decodeHTML(html) {
            var txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },
        getDescription(html) {
            var noHTML = this.decodeHTML(html).replace(/<[^>]+>/g, '');
            noHTML = noHTML.replace(/&nbsp:&nbsp;/g,'')
            var txtArr = noHTML.split('.');
            return txtArr[0] + '. ';
        },
    }
}
</script>
