<template>
    <div class="main-nav">
        <ul v-if="json">
            <li
                v-for="(item, index) in json.header.nav"
                :key="`main-nav-${index}`"
            >
                <Link
                    :href="item.href"
                    :target="item.target"
                    :label="item.label"
                    classes="link-underline-leftright"
                />
            </li>
        </ul>
        <div class="socicons">
            <div class="s1" v-for="(item, index) in this.socialMedia" :key="`navsocicon-${index}`">
                <a  :title="item.title" :href="item.link" target="_blank">
                    <span :class="`socicon socicon-${item.sociconId}`"></span>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
import Link from '@/components/pieces/Link'
export default {
    name: "MainNav",
    components: {
        Link
    },
    data() {
        return {
            window:window,
            json:null,
            socialMedia:[]
        }
    },
    mounted() {
        this.json = this.window.config.json.global
        this.socialMedia = this.window.config.json.global.socialMedia
    },
    methods: {

    }
}
</script>
