<template>
    <div class="header-outer">
        <header id="header" v-if="json" :class="[`route-type-${routeType}`,(smallHeader) ? 'small' : '']">
            <div class="cap">
                <div class="cap-padded">
                    <div class="tt">
                        <router-link
                            to="/"
                        >
                            <inline-svg :src="require('@/assets/images/global/tt-horizontal.svg')" />
                        </router-link>
                    </div>
                    <TopCTAs v-show="mobileNavOpen"/>
                </div>
            </div>
            <div class="menu-outer">
                <div class="cap">
                    <div class="cap-padded">
                        <div :class="['menu',(mobileNavOpen) ? 'open' : '']" @click="toggleNav">
                            <div class="txt">
                                <span v-if="mobileNavOpen">close</span>
                                <span v-else>menu</span>
                            </div>

                            <div class="hamburger">
                                <div class="cross">
                                    <div class="line top"></div>
                                    <div class="line bottom"></div>
                                    <div class="slant forward"></div>
                                    <div class="slant backward"></div>
                                </div>
                                <!--<inline-svg :src="require('@/assets/images/global/mobile-hamburger.svg')"></inline-svg>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <nav class="mobile-nav">
                <div class="cap">
                    <div class="cap-padded">
                        <div class="mobile-tt">
                            <inline-svg :src="require('@/assets/images/global/tt-vertical.svg')" />
                        </div>
                        <MainNav></MainNav>
                        <div class="mobile-theme-holder">
                            <div class="mobile-theme" @click="toggleTheme">
                                <div class="icon">
                                    <inline-svg :src="require('@/assets/images/global/icon-theme.svg')"></inline-svg>
                                </div>
                                <div class="txt">
                                    <span>THEME</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </nav>
        </header>
        <div class="header-pusher"></div>
    </div>

</template>
<script>
//state
import { mapState } from 'vuex'
import gsap, {Power3} from '@/assets/js/gsap'
import MainNav from '@/components/framework/sub/MainNav'
import TopCTAs from '@/components/framework/TopCTAs'
import gh from '@/helpers/gh'
export default {
    name: "Header",
    components: {
        MainNav,
        TopCTAs
    },
    data() {
        return {
            document:document,
            window:window,
            json:null,
            routeType:'',
            isSmallHeader:false,
            mobileNavOpen:false,
            mobileNavTransition:false,
        }
    },
    computed: {
        ...mapState({
            smallHeader: state => state.smallHeader,
            mobileCloseToggler: state => state.mobileCloseToggler,
        })
    },
    watch: {
        smallHeader() {
            this.isSmallHeader = this.smallHeader
        },
        mobileCloseToggler() {
            this.closeNav()
        },
        $route (to){
            //console.log(to, from)
            this.routeType = to.meta.type
        },
    },
    mounted() {
        this.json = this.window.config.json.global
        //window.addEventListener('scroll', this.scroll)
    },

    methods: {
        scroll() {
            //this.$store.commit('setSmallHeader',(window.scrollY>0))
        },
        toggleNav() {
            if (this.mobileNavOpen) {
                this.closeNav()
            } else {
                this.openNav()
            }
        },
        openNav() {
            if (!this.mobileNavTransition) {
                this.mobileNavTransition = true
                this.mobileNavOpen = true

                let tl = gsap.timeline({onComplete:this.openNav2});
                tl.fromTo('#header .mobile-nav',
                    { height: 0 },
                    { height: '100vh', duration:0.5, delay:0,ease: Power3.easeOut },
                    0
                )
                tl.fromTo('#header .tt',
                    { opacity: 1 },
                    { opacity: 0, duration:0.5, delay:0,ease: Power3.easeOut },
                    0
                )
                tl.fromTo('#header .top-ctas',
                    { top: -130 },
                    { top: 0, duration:0.5, delay:0,ease: Power3.easeOut },
                    0.35
                )

                tl.fromTo('#header .mobile-tt',
                    { opacity: 0, y:-20 },
                    { opacity: 1, y:0, duration:0.5, delay:0,ease: Power3.easeOut },
                    "-=0.35"
                )
                tl.fromTo('#header .mobile-nav .main-nav li',
                    { opacity: 0, y:-20 },
                    { opacity: 1, y:0, duration:0.5, stagger:0.1, ease: Power3.easeOut },
                    "-=0.35"
                )
                /*
                tl.fromTo('#header .mobile-nav .socicons .s1',
                    { opacity: 0, x:-20 },
                    { opacity: 1, x:0, duration:0.5, stagger:0.05, ease: Power3.easeOut },
                    "-=0.35"
                )
                tl.fromTo('#header .mobile-nav .mobile-theme-holder',
                    { opacity: 0, x:-20 },
                    { opacity: 1, x:0, duration:0.5, stagger:0.05, ease: Power3.easeOut },
                    "-=0.35"
                )

                 */
            }

        },
        openNav2() {
            this.mobileNavTransition = false
        },
        closeNav() {
            if (this.mobileNavOpen) {
                this.mobileNavTransition = true
                let tl = gsap.timeline({onComplete:this.closeNav2})
                /*
                tl.fromTo('#header .mobile-nav .mobile-theme-holder',
                    { opacity: 1, x:0 },
                    { opacity: 0, x:-20, duration:0.5, stagger:-0.02, ease: Power3.easeOut },
                    0
                )
                tl.fromTo('#header .mobile-nav .socicons .s1',
                    { opacity: 1, x:0 },
                    { opacity: 0, x:-20, duration:0.5, stagger:-0.02, ease: Power3.easeOut },
                    0
                )

                 */
                tl.fromTo('#header .mobile-nav .main-nav li',
                    { opacity: 1, y:0 },
                    { opacity: 0, y:-20, duration:0.5, stagger:-0.05, ease: Power3.easeOut },
                    0
                )
                tl.fromTo('#header .mobile-tt',
                    { opacity: 1, y:0},
                    { opacity: 0,y:-20, duration:0.5, delay:0,ease: Power3.easeOut },
                    0.15
                )
                tl.fromTo('#header .mobile-nav',
                    { height: '100vh' },
                    { height: 0, duration:0.5, ease: Power3.easeOut},
                    0.25
                )
                tl.fromTo('#header .top-ctas',
                    { top: 0 },
                    { top: -130, duration:0.5, delay:0,ease: Power3.easeOut },
                    0.25
                )
                tl.fromTo('#header .tt',
                    { opacity: 0 },
                    { opacity: 1, duration:0.5, delay:0,ease: Power3.easeOut },
                    0.45
                )

            }
        },
        closeNav2() {
            this.mobileNavTransition = false
            this.mobileNavOpen = false
        },
        toggleTheme() {
            gh.toggleTheme(this.document)
        }
    }
}
</script>
