<template>
    <div class="home-nav">
        <div class="home-nav__page">
            <div class="home-nav__page__cur">0{{ section }}</div>
            <div class="home-nav__page__line"></div>
            <div class="home-nav__page__total">{{ inThePressHasContent ? '03' : '02' }}</div>
        </div>
        <div class="home-nav__line">
            <div></div>
        </div>
        <div class="home-nav__nav">
            <div :class="{ 'home-nav__nav--active' : section == 1 }" @click="$emit('update:section', 1)"></div>
            <div :class="{ 'home-nav__nav--active' : section == 2 }" @click="$emit('update:section', 2)"></div>
            <div :class="{ 'home-nav__nav--active' : section == 3 }" @click="$emit('update:section', 3)" v-if="inThePressHasContent"></div>
        </div>
        <div class="home-nav__line">
            <div></div>
        </div>
        <div class="home-nav__scroll">
            <svg fill="#000" enable-background="new 0 0 10.5 125.9" viewBox="0 0 10.5 125.9" xmlns="http://www.w3.org/2000/svg">
                <g enable-background="new">
                    <path d="m3.5 2.7c-.5.1-.8.2-1 .5s-.3.7-.3 1.3c0 .5.1.8.3 1.1.2.2.4.4.7.4.2 0 .4-.1.6-.2.1-.3.2-.6.3-1l.3-1.3c.2-1.1.6-1.9 1-2.5.5-.5 1.2-.8 2-.8 1 0 1.8.4 2.3 1.1s.8 1.7.8 2.9c0 .7-.1 1.3-.3 1.9-.1.5-.3.9-.6 1.2-.3.4-.6.6-1 .8-.4.1-.8.3-1.2.3l-.2-2.6c.4-.1.7-.2.9-.5.2-.2.3-.6.3-1.1s-.1-.8-.2-1c-.2-.2-.4-.3-.7-.3-.2 0-.4 0-.5.2s-.3.5-.4.9l-.2 1.3c-.2 1.2-.6 2-1.1 2.5s-1.2.7-2 .7c-.5 0-.9-.1-1.3-.3s-.7-.4-1-.8c-.4-.2-.6-.6-.8-1.2-.1-.5-.2-1.1-.2-1.8 0-2.8 1.2-4.3 3.5-4.4z" />
                    <path d="m3.9 32.8c-.6 0-1.1-.2-1.5-.3-.5-.2-.9-.5-1.2-.8-.3-.4-.6-.8-.8-1.4-.3-.6-.4-1.2-.4-1.9 0-.8.1-1.5.4-2.1s.6-1.1 1.1-1.5 1-.7 1.6-.9 1.3-.3 2.1-.3 1.5.1 2.1.3c.7.2 1.2.5 1.7.9s.8.9 1.1 1.5.4 1.3.4 2.1c0 1.2-.3 2.2-.9 2.9s-1.5 1.2-2.6 1.3l-.3-2.6c.2 0 .4-.1.6-.2s.3-.2.5-.3c.1-.1.2-.3.3-.5s.1-.4.1-.7c0-1.3-1-1.9-2.9-1.9-.5 0-1 0-1.4.1s-.7.3-.9.5-.4.4-.5.6-.2.5-.2.8c0 1 .5 1.5 1.6 1.7z" />
                    <path d="m7.1 56c-.6 0-1.2-.1-1.7-.4-.5-.2-.9-.6-1.2-1.1l-4 2v-2.8l3.4-1.6v-1.5h-3.3v-2.6h10v4c0 .8-.1 1.4-.3 1.9s-.4.9-.7 1.2-.6.5-1 .6-.8.3-1.2.3zm-1.3-5.4v1.4c0 .5.1.9.3 1.1s.5.3.9.3.7-.1.9-.4c.2-.2.3-.6.3-1.2v-1.2z" />
                    <path d="m10.5 76.1c0 .8-.1 1.5-.4 2.1-.2.6-.6 1.1-1 1.5s-1 .7-1.6.9-1.4.4-2.2.4-1.5-.1-2.2-.3-1.2-.5-1.7-1-.8-.9-1.1-1.6c-.2-.6-.3-1.3-.3-2.1s.1-1.5.4-2.1c.2-.6.6-1.1 1-1.5s1-.7 1.6-.9 1.4-.3 2.2-.3 1.5.1 2.2.3 1.2.5 1.7 1c.5.4.8.9 1.1 1.6.2.5.3 1.2.3 2zm-5.3-2.2c-1 0-1.7.2-2.2.5-.5.4-.7.9-.7 1.6s.2 1.3.7 1.6 1.2.5 2.2.5 1.7-.2 2.2-.5.7-.9.7-1.6-.2-1.3-.7-1.6-1.2-.5-2.2-.5z" />
                    <path d="m10.2 96.4v2.7h-7.7v4.4l-2.3-.2v-6.9z" />
                    <path d="m10.2 118.8v2.7h-7.7v4.4l-2.3-.2v-6.9z" />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            section: Number,
            json: Object
        },
        computed: {
            inThePressHasContent() {
                return this.json.inThePress.slides && this.json.inThePress.slides.length;
            }
        }
    }
</script>