<template>
    <div class = "lottie">
        <div v-if="trigger == 'hover'" ref = "lottie" v-sr="{ beforeReveal: handleTrigger, distance: 0, opacity: 1 }" @mouseenter="playAnimation(1)" @mouseleave="playAnimation(-1)"></div>
        <div v-else-if="trigger == 'load'" ref = "lottie" v-sr="{ beforeReveal: handleTrigger, distance: 0, opacity: 1 }"></div>
        <div v-else-if="trigger == 'function'" ref = "lottie"></div>
    </div>
</template>
<script>
import lottie from 'lottie-web';

export default {
    name: "LottieAnimation",
    props: [
        "path",
        "trigger",
        "hovering"
    ],
    data() {
        return {
            lottie: null,
        }
    },
    mounted () {
        this.lottie = lottie.loadAnimation({
            wrapper: this.$refs.lottie,
            animType: 'svg',
            loop: false,
            autoplay: false,
            animationData: this.path
        });
    },
    methods: {
        handleTrigger() {
            if(this.trigger == "load") {
                this.playAnimation();
            }
        },
        playAnimation(direction = null) {
            if(direction) {
                this.setDirection(direction);
            }
            this.lottie.play();
            this.$emit('playing', direction);
        },
        stopAnimation() {
            this.lottie.stop();
        },
        pauseAnimation() {
            this.lottie.pause();
        },
        setDirection(direction) {
            this.lottie.setDirection(direction);
        },
        goToAndStop(value, isFrame) {
            this.lottie.goToAndStop(value, isFrame);
        },
        goToAndPlay(value, isFrame) {
            this.lottie.goToAndPlay(value, isFrame);
        },
        playSegments(segments, forceFlag) {
            this.lottie.playSegments(segments, forceFlag)
        },
        rewindAnimation() {
            this.lottie.stop();
        }
    }
}
</script>
