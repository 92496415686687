<template>
    <div class="platforms-txt-inner">
        <div class="rte-nosr">
            <h3 v-if="json.platforms.length" class="stagger-line">Platforms</h3>
            <ul v-if="json.platforms.length">
                <li class="stagger-line" v-for="(item, index) in json.platforms" :key="`platforms-${index}`"><span>{{item}}</span></li>
            </ul>
            <div class="esrb stagger-line" :class="(json.platforms.length) ? 'has-platforms' : 'no-platforms'" v-if="json.esrbLogo">
                <img :src="json.esrbLogo"/>
            </div>
            <div class="website stagger-line" v-if="json.websiteLink">
                <a :href="json.websiteLink" target="_blank">
                    <button class="website-button">
                        <div class="website-l">
                            visit official website
                        </div>
                        <div class="website-r">
                            <inline-svg :src="require('@/assets/images/global/icon-world.svg')"></inline-svg>
                        </div>
                    </button>
                </a>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "GamesMastPlatforms",
    props: {
        json:Object
    }
}
</script>
