<template>
    <div class="in-the-press" v-sr="{ beforeReveal: this.playAnimation, distance: 0, opacity: 1 }">

        <div class="title">
            <h3 ref="title">IN THE PRESS</h3>
        </div>

        <div class="slider">


            <div class="swiper-container swiper-container--press">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(slide) in json.slides" :key="slide.title">
                        <div :style="slide.bgImage">
                            <link-content class="extra-hit" :href="slide.link.url" :target="slide.link.target"></link-content>

                            <link-content class="content" :href="slide.link.url" :target="slide.link.target">
                                <div class="content__title">
                                    <h4>{{ slide.title }}</h4>
                                </div>
                                <div class="content__bottom">
                                    <div class="content__bottom__logo">
                                        <img :src="slide.creditImage">
                                    </div>
                                    <div class="read-btn">
                                        <lottie-animation ref="right__button23" trigger="hover" :path="require('@/assets/lottie/read-button.json')" />
                                    </div>
                                </div>
                            </link-content>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ornament">
                <lottie-animation ref="ornament" trigger="function" :path="require('@/assets/lottie/press-ornament.json')" />
            </div>

            <div class="slider__controls" ref="controls" :class="{ 'disabled' : json.slides.length < 2 }">
                <div class="slider__controls__arrows">
                    <div class="slider__controls__right" ref="right" @mouseenter="$refs.right__button.playAnimation(1)" @mouseleave="$refs.right__button.playAnimation(-1)">
                        <lottie-animation ref="right__button" trigger="function" :path="require('@/assets/lottie/slider-right.json')" />
                    </div>
                    <div class="slider__controls__left" ref="left" @mouseenter="$refs.left__button.playAnimation(1)" @mouseleave="$refs.left__button.playAnimation(-1)">
                        <lottie-animation ref="left__button" trigger="function" :path="require('@/assets/lottie/slider-left.json')" />
                    </div>
                </div>
                <div class="slider__controls__counter">
                    <span class="active">{{ slideIndex + 1 }}</span> of {{ json.slides.length }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        SplitText
    } from '@/assets/js/gsap/SplitText'
    import gsap, {
        Power3
    } from '@/assets/js/gsap/gsap-core';
    import Swiper, {
        Navigation,
        Pagination,
        Autoplay
    } from 'swiper'
    import 'swiper/swiper-bundle.min.css'
    Swiper.use([Navigation, Pagination, Autoplay])
    import LinkContent from '@/components/pieces/LinkContent'

    export default {
        props: {
            json: Object
        },
        components: {
            LinkContent
        },
        data() {
            return {
                activeSlide: 0,
                slider: null,
                resizeTimeout: null,
                slideIndex: 0,
            }
        },
        mounted() {
            this.$store.commit('setLargeSidebar', false);
            this.tl = gsap.timeline({
                delay: this.isMobile ? 0 : 0.4,
                paused: this.isMobile
            });
            this.splitTitleText();
            this.initSlider();
            this.initAnimation();

        },
        methods: {
            initAnimation() {
                this.tl.from('.in-the-press', {
                        opacity: 0,
                        duration: 0
                    })
                    .from(this.$refs.title.querySelectorAll('.title-line-split'), {
                        duration: 0.7,
                        x: 100,
                        opacity: 0,
                        ease: Power3.easeOut,
                        stagger: 0.06,
                        letterSpacing: '2rem'
                    }, 0)
                    .from('.swiper-container--press', {
                        duration: 0.9,
                        opacity: 0,
                        scale: 0.5,
                        ease: Power3.easeInOut
                    }, "-=0.6")
                    .add(() => {
                        this.$refs.ornament.playAnimation();
                    }, '-=0.4')
                    .from(this.$refs.right, {
                        duration: 0.7,
                        x: -100,
                        opacity: 0,
                        scale: 1.6,
                        ease: Power3.easeOut
                    }, 1.3)
                    .from(this.$refs.left, {
                        duration: 0.7,
                        x: 100,
                        opacity: 0,
                        scale: 1.6,
                        ease: Power3.easeOut
                    }, 1.3)
                    .from('.slider__controls__counter', {
                        duration: 0.7,
                        opacity: 0,
                        scale: 1.2,
                        ease: Power3.easeOut
                    }, 1.4)
            },
            playAnimation() {
                this.tl.play();
            },
            splitTitleText() {
                new SplitText(this.$refs.title, {
                    type: 'lines',
                    linesClass: 'title-line-split'
                });
            },
            initSlider() {

                this.slider = new Swiper('.swiper-container--press', {
                    loop: this.json.slides.length > 1,
                    speed: 500,
                    grabCursor: true,
                    slidesPerView: 1.18,
                    spaceBetween: 15,
                    preventClicks: true,
                    navigation: {
                        nextEl: this.$refs.right,
                        prevEl: this.$refs.left,
                    },
                    breakpoints: {
                        850: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        },
                    }
                });

                // need to trigger animation to play on section load
                // this.$refs.ornament.playAnimation();

                this.slider.on('slideChangeTransitionStart', function() {
                    this.$refs.ornament.rewindAnimation();
                }.bind(this))

                this.slider.on('sliderMove', function() {
                    this.$refs.ornament.rewindAnimation();
                }.bind(this))

                this.slider.on('slideChangeTransitionEnd', function() {
                    this.$refs.ornament.playAnimation();
                    this.slideIndex = this.getSlideDataIndex(this.slider);
                }.bind(this))

                // fixes issue where transitioning from home section 1 to section 3 resizes nav and causes size of slider to change which requires an update. 
                setTimeout(() => {
                    this.slider.update();
                }, 350)

                window.addEventListener('resize', this.onResize);

            },
            onResize() {
                // update slider after it scales and sometimes there's a 1px line visible
                clearTimeout(this.resizeTimeout);
                this.resizeTimeout = setTimeout(() => {
                    this.slider.update();
                }, 1000);
            },
            getSlideDataIndex(swipe) {
                var activeIndex = swipe.activeIndex;
                var slidesLen = swipe.slides.length;
                if (swipe.params.loop) {
                    switch (swipe.activeIndex) {
                        case 0:
                            activeIndex = slidesLen - 3;
                            break;
                        case slidesLen - 1:
                            activeIndex = 0;
                            break;
                        default:
                            --activeIndex;
                    }
                }
                return activeIndex;
            }
        },
        computed: {
            isMobile() {
                return window.innerWidth < 850;
            },
        },
        watch: {
            didInitialSiteTransition() {
                this.initAnimation();
            }
        },
        beforeUnmount() {
            this.slider.destroy();
            window.removeEventListener('resize', this.onResize);
        },
    }
</script>