<template>
    <section v-if="json" class="page-games">
        <div class="cap">
            <div class="masthead-outer">
                <div class="masthead">
                    <div class="cap-margin-padded">
                        <div class="copy">
                            <div class="copy2">
                                <div class="rte-nosr">
                                    <h1 v-html="json.masthead.title"></h1>
                                </div>
                                <div class="show-desktop">
                                    <p class="caption" v-html="json.masthead.caption"></p>
                                    <div class="global-line-nosr"></div>
                                </div>
                            </div>
                        </div>
                        <div class="featured" >
                            <div class="featured2" ><div class="featured3" >
                                <div class="bgimg" :style="json.masthead.bgWithStyle"></div>
                            </div></div>
                            <div class="lines">
                                <lottie-animation trigger = "function" ref="toplines" :path="require('@/assets/lottie/header.json')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="games-description-mobile show-mobile">
                <div class="cap-padded-2x">
                    <div class="rte">
                        <p class="caption" v-html="json.masthead.caption"></p>
                        <div class="global-line"></div>
                    </div>
                </div>

            </div>
            <div class="games">
                <Games_GameInner v-for="(item, index) in json.games"
                    :key="`game-item-${index}`"
                    :item="item"
                    :index="index"></Games_GameInner>
            </div>
        </div>
    </section>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import gsap, { Power3 } from '@/assets/js/gsap/gsap-core'
import { SplitText } from '@/assets/js/gsap/SplitText'
import Games_GameInner from '@/components/pages/sub/Games_GameInner'
export default {
    name: "Games",
    data () {
        return {
            window: window,
            json: null,
        }
    },
    components:{
        Games_GameInner,
    },
    mounted () {
        axios.get((`${window.config.apiroot}games${window.config.apiext}`))
            .then((res) => {
                this.json = res.data
                if (this.didInitialSiteTransition) {
                    setTimeout(function() {
                        this.reveal(1)
                    }.bind(this),100)
                }
            }).catch(() => {
        })

    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition
        }),
    },
    watch: {
        didInitialSiteTransition() {
            this.reveal(0)
        }
    },
    methods: {
        reveal (delay) {
            this.splitText()

            const linesDelay = (delay*1000) + 1000
            setTimeout(function() {
                this.$refs.toplines.playAnimation(1)
            }.bind(this), linesDelay)

            let tl = gsap.timeline()
            const tme = 0.7
            const stagger = "-=0.35"
            const stagger2 = "-=0.7"
            tl.fromTo('.page-games .masthead .copy',
                {
                    x: '100%',
                },
                {
                    x: 0,
                    duration: tme,
                    ease: Power3.easeOut
                },
                delay
            )
            tl.fromTo('.page-games .masthead .featured3',
                {
                    //height: 0,
                    scale:1.4,
                    opacity:0,
                },
                {
                    //height: '100%',
                    scale:1,
                    opacity:1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                "-=0"
            )

            tl.fromTo(this.splitTitle.words,
                {
                    x: -100,
                    opacity: 0,
                    letterSpacing: '0.02rem'
                },
                {
                    x: 0,
                    opacity: 1,
                    duration: 0.7,
                    ease: "power3",
                    stagger: 0.06,
                    letterSpacing: '0'
                },
                stagger2
            )
            tl.fromTo(this.splitCaption.words,
                {
                    x: -100,
                    opacity: 0,
                    letterSpacing: '0.02rem'
                },
                {
                    x: 0,
                    opacity: 1,
                    duration: 0.7,
                    ease: "power3",
                    stagger: 0.06,
                    letterSpacing: '0'
                },
                stagger
            )
            tl.fromTo('.global-line-nosr',
                {
                    width: 0,
                },
                {
                    width: 140,
                    duration: tme,
                    ease: Power3.easeOut
                },
                stagger
            )

        },
        splitText() {
            this.splitTitle = new SplitText('.page-games .masthead h1', {
                type:'words'
            })
            this.splitCaption = new SplitText('.page-games .masthead .caption', {
                type:'words'
            })
        },
    }
}
</script>
