<template>
    <div :class="['block block-quote',(json.bg) ? 'img' : '']" >
        <div v-if="json.bg" class="bg" v-sr >
            <img :src="json.bg"/>
        </div>
        <div v-if="json.bg" class="cap-padded-2x">
            <div class="quote-holder" v-sr >
                <div class="quote-copy">
                    <div class="quote-icon">
                        <inline-svg :src="require('@/assets/images/global/quote-white.svg')" />
                    </div>
                    <div class="quote-copy2">
                        <div class="quote" v-html="json.quote"></div>
                        <div class="name" v-html="json.name"></div>
                        <div class="attribute" v-html="json.attribute"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="no-img" >
            <div class="quote-holder" v-sr >
                <div class="quote-icon show-desktop">
                    <inline-svg :src="require('@/assets/images/global/quote-thin.svg')" />
                </div>
                <div class="quote-icon show-mobile">
                    <inline-svg :src="require('@/assets/images/global/quote-thin-mobile.svg')" />
                </div>
                <div class="quote-copy">
                    <div class="quote-copy1">
                        <div class="cap-padded-2x">
                            <div class="quote-copy2">
                                <div class="quote" v-html="json.quote"></div>
                                <div class="name" v-html="json.name"></div>
                                <div class="attribute" v-html="json.attribute"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Quote",
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
    },

}
</script>
