<template>
    <section v-if="json" class="page-generic">
        <div class="cap">
            <div class="generic-title" >
                <div class="generic-title2">
                    <div class="rte">
                        <h1 v-html="json.title"></h1>
                        <div class="global-line"></div>
                    </div>
                </div>
            </div>
            <div class="cap-padded-2x">
                <div class="rte" v-html="json.body"></div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import gh from '@/helpers/gh'

export default {
    name: "Generic",
    components: {

    },
    data () {
        return {
            window: window,
            json: null,
            transition: false,
        }

    },
    created () {
    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        let slugHook = (window.config.apiext.charAt(0) === '?') ? '&' : '?'
        axios.get((`${window.config.apiroot}generic${window.config.apiext}${slugHook}slug=${slug}`))
            .then((res) => {
                this.json = res.data
                this.reveal()
            }).catch((e) => {
            console.error('Error', slug, e)
        })
        this.reveal()
    },
    methods: {
        reveal () {

        },
        unreveal () {

        },
    }
}
</script>
