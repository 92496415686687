<template>
    <div v-show="showPopup" id="popup">
        <div class="popup-scrim popup-animate" @click="closePopup"></div>
        <div class="popup-inner popup-animate" style="pointer-events:none;">
            <div id='popup-video' v-show="obj?.type==='video'" style="pointer-events:all;"></div>
            <div id='popup-bio' v-show="obj?.type==='bio'" style="pointer-events:all;">
                <div v-if="bio" :class="'tnm__popup global-fonts ' + (!bio.bgWithStyle ? 'no-image ': '') + (!bio.bio ? 'no-bio ': '')">
                    <div :class="'tnm__popup__image ' + bio.id" :style="bio.bgWithStyle"></div>
                    <div class = "tnm__popup__info" ref = "info">
                        <div class = "tnm__popup__info__header">
                            <h3 ref = "name">{{ bio.name }}</h3>
                            <p ref = "title" class = "tnm__popup__info__title">{{ bio.title }}</p>
                        </div>
                        <div class = "tnm__popup__buttons">
                            <template v-for="(link, key) in bio.socialMedia" :key="`link-${key}`">
                                <div v-if="(key == 'linkedin' && link)" @click="window.open(link, '_blank');" class = "tnm__popup__buttons__button">
                                    <span class = "tnm__popup__buttons__button__text">connect on LinkedIn</span>
                                    <div class = "tnm__popup__buttons__button__icon"><inline-svg :src="require('@/assets/images/global/linkedin.svg')"></inline-svg></div>
                                </div>
                                <div v-else-if="(key == 'twitter' && link)" @click="window.open(link, '_blank');" class = "tnm__popup__buttons__button">
                                    <span class = "tnm__popup__buttons__button__text">connect on Twitter</span>
                                    <div class = "tnm__popup__buttons__button__icon"><inline-svg :src="require('@/assets/images/global/twitter.svg')"></inline-svg></div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class = "tnm__popup__bio" v-html="bio.bio"></div>
                </div>
            </div>
        </div>
        <div class="popup-close popup-animate" @click="closePopup" >
            <div class="popup-close2">
                <span>close</span>
                <inline-svg :src="require('@/assets/images/global/popup-close.svg')" />
            </div>
        </div>
    </div>
</template>
<script>
//state
import { mapState } from 'vuex'
//import gh from '@/helpers/gh'
import gsap, {Linear} from '@/assets/js/gsap'
export default {
    name: "Popup",
    data() {
        return {
            document:document,
            window:window,
            json:null,
            player:null,
            bio:null,
            inTransition:false,
            showPopup:false
        }
    },
    computed: {
        ...mapState({
            popupOpen: state => state.popupOpen,
            obj: state => state.popupObject
        }),
    },
    watch: {
        popupOpen() {
            if (this.popupOpen) {
                this.reveal()
            } else {
                this.unreveal()
            }
        }
    },
    mounted() {
        window.addEventListener('keyup', this.detectEscapeKey)

        var tag = document.createElement('script');
        tag.id = 'iframe-yt';
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    },
    methods: {
        detectEscapeKey(e) {
            if (e.keyCode === 27) {
                this.closePopup()
            }
        },
        reveal() {
            if (!this.inTransition) {
                this.showPopup = true
                this.inTransition = true

                if (this.obj?.type==='video') {
                    this.videoInit()
                }

                if (this.obj?.type==='bio') {
                    this.bio = this.obj?.item
                }

                //gh.enableBodyScroll(false)
                let tl = gsap.timeline({onComplete:this.reveal2})
                tl.fromTo('#popup .popup-animate',
                    {
                        //left: '100%',
                        opacity:0
                    },
                    {
                        //left: '0%',
                        opacity:1,
                        duration:0.35,
                        ease: Linear.easeNone
                    },
                    0
                )
            }

        },
        reveal2() {
            this.inTransition = false
        },
        closePopup() {
            this.$store.commit('closePopup')
        },
        unreveal() {
            if (!this.inTransition) {
                this.inTransition = true
                let tl = gsap.timeline({onComplete:this.unreveal2})
                tl.fromTo('#popup .popup-animate',
                    {
                        //left: 0,
                        opacity:1
                    },
                    {
                        //left: '-100%',
                        opacity:0,
                        duration:0.35,
                        ease: Linear.easeNone
                    },
                    0
                )
            }

        },
        unreveal2() {
            this.videoClear()
            this.inTransition = false
            this.showPopup = false
            //gh.enableBodyScroll(true)
        },

        //VIDEO
        videoInit() {
            this.videoClear()
            this.player = new this.window.YT.Player('youtube-video-popup', {
                height: '100%',
                width: '100%',
                host: 'https://www.youtube-nocookie.com',
                videoId: this.obj.videoId,
                playerVars: {
                    autoplay:1,
                    rel: 0,
                    modestbranding:1
                },
                events: {
                    'onReady': this.onPlayerReady,
                    'onStateChange': this.onPlayerStateChange
                }
            })
        },
        videoClear() {
            try {
                this.document.getElementById("popup-video").innerHTML = '<div id="youtube-video-popup"></div>'
                this.player.stopVideo()
            } catch {
                this.catchBypasser()
           }
        },
        onPlayerReady() {
            try {
                this.player.playVideo()
            } catch {
                this.catchBypasser()
           }
        },
        onPlayerStateChange(event) {
            if (event.data === this.window.YT.PlayerState.ENDED) {
                this.closePopup()
            }
        },
        catchBypasser() {

        }
    }
}
</script>
