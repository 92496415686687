<template>
    <div :class="['block block-image', (json.caption) ? 'has-caption' : 'no-caption']" >
        <div v-sr>
            <div v-if="json.bg" class="bg" >
                <img :src="json.bg"/>
            </div>
            <Caption v-show="(json.caption)" :caption="json.caption"/>
        </div>
    </div>
</template>
<script>
import Caption from '@/components/blocks/generic/sub/Caption'
export default {
    name: "Image",
    components: {
        Caption
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
    },

}
</script>
