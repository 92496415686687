<template>
    <div class = "tnm__contactform" v-sr>
        <div class = "tnm__contactform__title" ref = "header">
            <h2 ref = "title">{{ block.title }}</h2>
            <span class = "tnm__contactform__title__rule tnm__rule" ref = "rule"></span>
        </div>
        <div class = "tnm__contactform__holder" v-if="activeForm">
            <div class = "tnm__contactform__selectform">
                <span class = "tnm__contactform__selectform__label">please select:</span>
                <div class = "tnm__contactform__selectform__choose">
                    <div ref = "dropdown" class="tnm__contactform__selectform__choose__dropdown" @click="toggleDropdown">
                        <div class = "tnm__contactform__selectform__choose__dropdown__text">{{ activeForm.name }}</div>
                        <div class = "tnm__contactform__selectform__choose__dropdown__forms" ref = "forms">
                            <div class = "tnm__contactform__selectform__choose__dropdown__forms__inner">
                                <div v-for="(form, index) in block.forms" :key="`form-${index}`" :class="'tnm__contactform__selectform__choose__dropdown__forms__choice ' + (activeForm.id == form.id ? 'selected' : '')" @click="selectForm(form.id)">
                                    {{ form.name }}
                                </div>
                            </div>
                            <inline-svg :src="require('@/assets/images/who-we-are/dropdown.svg')"></inline-svg>
                        </div>
                        <span class = "normal"><inline-svg :src="require('@/assets/images/who-we-are/dropdown.svg')"></inline-svg></span>
                        <span class = "hover"><inline-svg :src="require('@/assets/images/who-we-are/dropdown-hover.svg')"></inline-svg></span>
                    </div>
                </div>
            </div>
            <Form v-show="activeForm" :key="formRekey" :form="activeForm"/>
        </div>
    </div>
</template>
<script>
import Form from '@/components/pieces/Form.vue';
import gsap from '@/assets/js/gsap/gsap-core';
export default {
    name: "ContactForm",
    components: {
        Form
    },
    data() {
        return {
            activeForm: null,
            active: false,
            formRekey:Math.random()
        }
    },
    props: [
        'block'
    ],
    mounted () {
        this.selectForm('press');
    },
    methods: {
        selectForm(id) {
            this.activeForm = null;
            for(var x = 0; x < this.block.forms.length; x++) {
                if(this.block.forms[x].id == id) {
                    this.activeForm = this.block.forms[x]
                    this.formRekey = Math.random()
                }
            }
        },
        toggleDropdown() {
            var tl = gsap.timeline();
            if(this.active) {
                tl
                    .to(this.$refs.forms, { duration: 0.4, height: 0, ease: 'power3' }, 0)
                    .add(() => { this.$refs.dropdown.classList.remove('active'); }, '-=.1')
                    .add(() => { this.active = !this.active }, 0)
            } else {
                tl
                    .add(() => { this.$refs.dropdown.classList.add('active'); }, 0)
                    .fromTo(this.$refs.forms, { height: 0 }, { duration: 0.3, height: 'auto', ease: 'power3' }, 0)
                    .add(() => { this.active = !this.active; }, 0)
            }
        }
    }
}
</script>
