<template>
    <div v-if="json.videoId && json.bg"  class = "tnm__video">
        <div class = "tnm__animation-holder tnm__video__header">
            <div class = "tnm__video__animation-holder">
                <div class = "tnm__video__animation-holder__animation tnm__fuzzy-circle black center">
                    <fuzzy-circle ref = "large__circle_inner" type="big-black"/>
                    <div class = "tnm__small-circle bottom left gray front transparent">
                        <fuzzy-circle ref = "large__circle_inner" type="small-gray"/>
                    </div>
                    <div class = "tnm__overlay">
                        <lottie-animation trigger = "load" :path="require('@/assets/lottie/header-sphere.json')" />
                    </div>
                    <h2 class = "tnm__text">
                        {{ json.title }}
                    </h2>
                </div>
            </div>
        </div>
        <div class = "tnm__video__holder">
            <Video :json="json"/>
        </div>
    </div>
</template>
<script>
import Video from '@/components/blocks/generic/Video'
export default {
    name: "Video Wrapper",
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
    },
    components: {
        Video
    }
}
</script>
