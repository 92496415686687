<template>
    <div class = "tnm__meet-the-team__controls">
        <div class = "tnm__meet-the-team__controls__prev" @click="prevDepartment">
            <inline-svg :src="require('@/assets/images/who-we-are/prevdepartment.svg')"></inline-svg>
        </div>
        <div class = "tnm__meet-the-team__controls__choose">
            <div ref = "dropdown" class="tnm__meet-the-team__controls__choose__dropdown" @click="toggleDropdown">
                <div class = "tnm__meet-the-team__controls__choose__dropdown__text">{{ activeDepartment.name }}</div>
                <div class = "tnm__meet-the-team__controls__choose__dropdown__departments" ref = "departments">
                    <div class = "tnm__meet-the-team__controls__choose__dropdown__departments__inner">
                        <div :class="'tnm__meet-the-team__controls__choose__dropdown__departments__choice ' + (activeDepartment.id == 'all' ? 'selected' : '')" @click="selectDepartment('all', 'View All Departments')">
                            All Departments
                        </div>
                        <div v-for="(department, index) in departments" :key="`department-${index}`" :class="'tnm__meet-the-team__controls__choose__dropdown__departments__choice ' + (activeDepartment.id == department.id ? 'selected' : '')" @click="selectDepartment(department.id, department.name)">
                            {{ department.name }}
                        </div>
                    </div>
                    <inline-svg :src="require('@/assets/images/who-we-are/dropdown.svg')"></inline-svg>
                </div>
                <span class = "normal"><inline-svg :src="require('@/assets/images/who-we-are/dropdown.svg')"></inline-svg></span>
                <span class = "hover"><inline-svg :src="require('@/assets/images/who-we-are/dropdown-hover.svg')"></inline-svg></span>
            </div>
        </div>
        <div class = "tnm__meet-the-team__controls__next" @click="nextDepartment">
            <inline-svg :src="require('@/assets/images/who-we-are/nextdepartment.svg')"></inline-svg>
        </div>
    </div>
</template>
<script>
import gsap from '@/assets/js/gsap/gsap-core';
export default {
    name: "Team Controls",
    props: ['activeDepartment', 'departments'],
    data() {
        return {
            active: false,
        }
    },
    computed: {
        activeDepartmentIndex() {
            var r = 0;
            for(var x = 0; x < this.departments.length; x++) {
                if(this.departments[x].id == this.activeDepartment.id) {
                    r = x;
                }
            }
            return r;
        }
    },
    methods: {
        toggleDropdown() {
            var tl = gsap.timeline();
            if(this.active) {
                tl
                    .to(this.$refs.departments, { duration: 0.4, height: 0, ease: 'power3' }, 0)
                    .add(() => { this.$refs.dropdown.classList.remove('active'); }, '-=.1')
                    .add(() => { this.active = !this.active }, 0)
            } else {
                tl
                    .add(() => { this.$refs.dropdown.classList.add('active'); }, 0)
                    .fromTo(this.$refs.departments, { height: 0 }, { duration: 0.3, height: 'auto', ease: 'power3' }, 0)
                    .add(() => { this.active = !this.active; }, 0)
            }
        },
        selectDepartment(id, name) {
            this.$emit('newDepartment', { id: id, name: name });
        },
        nextDepartment() {
            var department;
            if((this.activeDepartmentIndex+1) > (this.departments.length -1)) {
                department = this.departments[0];
            } else {
                department = this.departments[this.activeDepartmentIndex+1];
            }
            this.$emit('newDepartment', { id: department.id, name: department.name });
        },
        prevDepartment() {
            var department;
            if((this.activeDepartmentIndex-1) < 0) {
                department = this.departments[(this.departments.length - 1)];
            } else {
                department = this.departments[this.activeDepartmentIndex-1];
            }
            this.$emit('newDepartment', { id: department.id, name: department.name });
        },
    }
}
</script>
