//gh - global helper - you can conveniently add some global functions here to reuse
//import AOS from 'aos'
import createjs from 'preload-js'
import { gsap, Power3 } from '@/assets/js/gsap'
import { ScrollToPlugin } from '@/assets/js/gsap/ScrollToPlugin'
//import { CSSRulePlugin } from '@/assets/js/gsap/CSSRulePlugin'
gsap.registerPlugin(ScrollToPlugin)
//gsap.registerPlugin(CSSRulePlugin)

import ScrollReveal from 'scrollreveal'
const gh = {
    globalSectionInit: function() {
        //global code you want to init can go here
        setTimeout(function() {
            const obj = window.config.globalScrollReveal
            ScrollReveal().reveal('.rte h1', obj)
            ScrollReveal().reveal('.rte h2', obj)
            ScrollReveal().reveal('.rte h3', obj)
            ScrollReveal().reveal('.rte h4', obj)
            ScrollReveal().reveal('.rte h5', obj)
            ScrollReveal().reveal('.rte h6', obj)
            ScrollReveal().reveal('.rte p', obj)
            ScrollReveal().reveal('.rte ol', obj)
            ScrollReveal().reveal('.rte ul', obj)
            ScrollReveal().reveal('.rte li', obj)
            ScrollReveal().reveal('.rte iframe', obj)
            ScrollReveal().reveal('.rte figure', obj)
            ScrollReveal().reveal('.rte img', obj)
            ScrollReveal().reveal('.rte blockquote', obj)
            ScrollReveal().reveal('.rte pre', obj)

            //
            const objScaleUp = {
                duration: 850,
                scale: 0,
                delay:300,
                viewOffset: {
                    bottom: 100
                }
            }
            ScrollReveal().reveal('.global-line', objScaleUp)

            //
            const objScaleDown = {
                duration: 850,
                scale: 1.4,
                delay:300,
                viewOffset: {
                    bottom: 100
                }
            }
            ScrollReveal().reveal('.sr-scaledown', objScaleDown)


            //
            const objl = {
                duration: 850,
                origin: 'left',
                distance:'100px',
                delay:0,
                viewOffset: {
                    bottom: 100
                }
            }
            ScrollReveal().reveal('.sr-lhook', objl)

            //
            const objr = {
                duration: 850,
                origin: 'right',
                distance:'100px',
                delay:0,
                viewOffset: {
                    bottom: 100
                }
            }
            ScrollReveal().reveal('.sr-rhook', objr)


        },100)
    },
    aosInit: function() {
        /*
        AOS.init({
            offset: 25,
            duration: 800,
            once: true,
        })
        gh.aosRefresh()

         */
    },
    aosRefresh: function() {
        //AOS.refresh()
        //AOS.refreshHard()
    },
    postLoadAssets: function(assets) {
        let queue = new createjs.LoadQueue()
        queue.setMaxConnections(100)
        //queue.on("progress", function(e) {console.log(e)}, this)
        //queue.on("complete", this.postloadComplete, this)
        //queue.on("error", this.postloadError, this)
        queue.loadManifest(assets)
    },
    //divId = the id to scroll to, aka, '#follow-hook'
    scrollTo(divId, window) {
        gsap.to(window, {
            duration: 0.5,
            scrollTo:divId,
            easing: Power3.easeInOut
        })
    },
    enableBodyScroll(enable) {
        if (enable) {
            document.body.classList.remove("no-scroll")
            window.scrollTo(0, gh.bodyScrollY)
            document.body.style.top = 0
        } else {
            gh.bodyScrollY = window.scrollY
            document.body.classList.add("no-scroll")
            document.body.style.top = -gh.bodyScrollY+'px'
        }
    },
    stripSlashesFrontAndBack(val) {
        if (val) {
            val = val.toString()
            val = val.split('?')[0]

            if(val.substr(-1) === '/') {
                val = val.substr(0, val.length - 1);
            }
            if(val.substr(0,1) === '/') {
                val = val.substr(1, val.length);
            }
        }
        return val

    },
    dateToMONDDYYYY(dateString, monthStyle='short', separator='') {
        const objDate = new Date(dateString + 'T00:00:00')
        const month = objDate.toLocaleString('default', { month: monthStyle })
        const day = objDate.getDate()
        const year = objDate.getFullYear()
        if (separator!=='') {
            separator = `<span class="separator">${separator}</span>`
        }
        return `<span class='month'>${month}</span>${separator}<span class='day'>${day}</span>${separator}<span class='year'>${year}</span>`
    },

    toggleTheme(document) {
        let bodyClasses = document.body.className
        // console.log('bodyClasses',bodyClasses)
        if (bodyClasses.includes("theme-dark")) {
            bodyClasses = bodyClasses.replace('theme-dark', 'theme-light')
        } else {
            bodyClasses = bodyClasses.replace('theme-light', 'theme-dark')
        }
        document.body.className = bodyClasses

        const toggle = new Event('toggle-theme');
        window.dispatchEvent(toggle);
    },
    getFacebookUrl(url) {
        return `https://www.facebook.com/sharer/sharer.php?u=${url}`
    },
    getTwitterUrl(url, tweet='') {
        if (tweet) {
            tweet = encodeURI(tweet) + ' '
        }
        return  `https://twitter.com/intent/tweet?text=${tweet}${url}`
    },
    getLinkedInUrl(url, title='',summary='',source='') {
        if (title) {
            title = '&title=' + encodeURI(title)
        }
        if (summary) {
            summary = '&summary=' + encodeURI(summary)
        }
        if (source) {
            source = '&source=' + encodeURI(source)
        }
        return  `https://www.linkedin.com/shareArticle?mini=true&url=${url}${title}${summary}${source}`
    },
    getSocialUrl(value, window) {
        let ret = ''
        const socialMedia = window.config.json.global.socialMedia
        value = String(value).toLowerCase()
        for (const obj of socialMedia) {
            if (obj.sociconId === value) {
                ret = obj.link
            }
        }
        return ret
    }
}
export default gh
