<template>
    <div class="block block-latest-news">
        <div v-if="json && json.items.length" class="block-latest-news2" v-sr>
            <div v-if="json.title" class="optional-block-subtitle rte no-max-width">
                <h2 v-html="json.title"></h2>
                <div class="global-line"></div>
            </div>
            <div class="items" v-sr>
                <div class="game-item-outer" v-for="(item, index) in json.items" :key="`latest-news-item-${index}`"
                     v-show="(index < currentItem) ">
                    <router-link :to="item.link" @mouseenter="$refs[`btn-preorder${index}`].playAnimation(1.75)"
                                 @mouseleave="$refs[`btn-preorder${index}`].playAnimation(-1.75)">
                        <div class="item" >
                            <div class="featured">
                                <div class="bgimg" :style="item.bgWithStyle"></div>
                                <div class="caption">
                                    <div class="caption2">
                                        <h3 v-html="item.title"></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="info">
                                    <div class="info2">
                                        <div class="author">{{ item.author }}</div>
                                        <div class="date">{{ item.date }}</div>
                                    </div>
                                </div>
                                <div class="btn noselect">
                                    <lottie-animation :ref="`btn-preorder${index}`" trigger="function"
                                                      :path="require('@/assets/lottie/read-button.json')"/>
                                </div>
                            </div>

                        </div>
                    </router-link>
                </div>

            </div>
        </div>
        <div v-show="showViewMore" class="load-more">
            <lottie-animation @click="loadMore" @mouseenter="$refs[`btn-viewmore${index}`].playAnimation(1)" @mouseleave="$refs[`btn-viewmore${index}`].playAnimation(-1)" :ref="`btn-viewmore${index}`" trigger="function" :path="require('@/assets/lottie/view-more.json')"/>
        </div>
    </div>
</template>

<script>
//import gsap, {Power3} from '@/assets/js/gsap'
import ScrollReveal from 'scrollreveal'
export default {
    name: "LatestNews",
    components: {},
    data () {
        return {
            itemsPerBlock: 3,
            currentBlock: 1,
        }
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
    },
    computed: {
        currentItem () {
            return this.currentBlock * this.itemsPerBlock
        },
        showViewMore () {
            return (this.currentItem < this.json.items.length)
        }
    },
    methods: {
        init () {
        },
        loadMore () {
            this.currentBlock++
            ScrollReveal().sync()
        }
    }
}
</script>
