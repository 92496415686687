<template>
    <video muted playsinline v-if="video" ref = "video" :style="'opacity:' + (type == 'big-gray' && theme == 'theme-light' ? '.3' : '1') + ';'">
        <source :src="'/assets/site/circle/video/' + video" :type="vtype">
    </video>
    <div v-else-if="image" class="fallback-image" :style="'background-image: url(/assets/site/circle/images/' + image + ');' + 'opacity:' + (type == 'big-gray' && theme == 'theme-light' ? '.48' : '1') + ';'" ref = "image"></div>
</template>
<script>
import { detect } from 'detect-browser'
import { isAndroid, isMobileSafari } from 'mobile-device-detect';
export default {
    name: "Fuzzy Circle",
    props: ['type'],
    data() {
        return {
            video: null,
            image: null,
            browser: detect(),
            vtype: '',
        }
    },
    mounted() {
        window.addEventListener('toggle-theme', this.updateCircle);

        this.createCircle();
    },
    methods: {
        playAnimation() {
            if(this.video) {
                this.$refs.video.play();
            }
        },
        pauseAnimation() {
            if(this.video) {
                this.$refs.video.pause();
            }
        },
        restartAnimation() {
            if(this.video) {
                this.$refs.video.currentTime = 0;
            }
        },
        reverseAnimation() {
            if(this.video) {
                var video = this.$refs.video;

                var fps = 60;
                var intervalRewind = setInterval(function() {
                    if(video.currentTime == 0){
                    clearInterval(intervalRewind);
                    video.pause();
                    }
                    else {
                        video.currentTime += -(1/fps);
                    }
                }, 1000 / fps);
            }
        },
        updateCircle() {
            this.createCircle();
            if(this.video) {
                this.$refs.video.load();
                this.playAnimation();
            }
        },
        createCircle() {
            let bodyClasses = document.body.className;
            if (bodyClasses.includes("theme-dark")) {
                this.theme = 'theme-dark';
            } else {
                this.theme = 'theme-light';
            }

            if(this.browser.name == 'safari' || isMobileSafari) {
                if(this.type == 'small-gray' || this.type == 'small-black') {
                    this.image = this.type + '--' + this.theme + '.png';
                    this.video = null;
                } else {
                    this.video = this.type + '--' + this.theme + '.mov';
                    this.vtype = "video/quicktime";
                    this.image = null;
                }
            } else if(this.canPlayWebM()) {
                this.video = this.type + '--' + this.theme + '.webm';
                this.vtype = "video/webm";
                this.image = null;
            } else {
                this.image = this.type + '--' + this.theme + '.png';
                this.video = null;
            }
        },
        canPlayWebM() {
            if((this.browser.name == 'firefox' || this.browser.name == 'chrome') && !isAndroid) {
                if(this.type == 'small-gray' || this.type == 'small-black') {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
    }
}
</script>