<template>
    <section v-if="json" class="page-news-detail">
        <ArticleMast :json="json.masthead" :relatedArticleCount="json.relatedArticles.length"></ArticleMast>
        <div class="blocks">
            <div class="cap-margin-padded-2x">
                <div class="solid-color-column">
                    <div v-for="(item, index) in json.blocks" :key="'block-wrapper-'+index" :class="['block-wrapper-'+item.type]">
                        <GenericText v-if="item.type==='text'" :json="item.content"></GenericText>
                        <Quote v-else-if="item.type==='quote'" :json="item.content"></Quote>
                        <Image v-else-if="item.type==='image'" :json="item.content"></Image>
                        <Video v-else-if="item.type==='video'" :json="item.content"></Video>
                        <Slides v-else-if="item.type==='slides'" :json="item.content"></Slides>
                    </div>
                </div>

                <div v-if="json.backtoNewsLink" class="block-wrapper-back">
                    <ButtonBack :href="json.backtoNewsLink" label="BACK TO NEWS"/>
                </div>
                <div v-if="json.relatedArticles.length" class="block-wrapper-related-articles">
                    <ArticleRelated :json="json.relatedArticles" :title="true"></ArticleRelated>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import gsap, { Power3 } from '@/assets/js/gsap/gsap-core'
import { SplitText } from '@/assets/js/gsap/SplitText'
import gh from '@/helpers/gh'
import ArticleMast from '@/components/blocks/generic/ArticleMast'
import GenericText from '@/components/blocks/generic/GenericText'
import Quote from '@/components/blocks/generic/Quote'
import Image from '@/components/blocks/generic/Image'
import Video from '@/components/blocks/generic/Video'
import Slides from '@/components/blocks/generic/Slides'
import ArticleRelated from '@/components/blocks/generic/ArticleRelated'
import ButtonBack from '@/components/pieces/ButtonBack'
export default {
    name: "NewsDetail",
    components: {
        ArticleRelated,
        ArticleMast,
        GenericText,
        Quote,
        Image,
        Video,
        Slides,
        ButtonBack
    },
    data () {
        return {
            window: window,
            json: null,
            gh:gh,
            splitTitle:null
        }
    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition
        }),
    },
    watch: {
        didInitialSiteTransition() {
            //called when didInitialSiteTransition
            this.reveal(0)
        }
    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        slug = slug.replace('news/','')
        //let slug = window.site_config?.site_segment2
        let slugHook = (window.config.apiext.charAt(0) === '?') ? '&' : '?'
        axios.get((`${window.config.apiroot}news-detail${window.config.apiext}${slugHook}slug=${slug}`))
            .then((res) => {
                this.json = res.data

                if (this.didInitialSiteTransition) {
                    setTimeout(function() {
                        this.reveal(1)
                    }.bind(this),100)
                }

            }).catch(() => {
        })
    },
    methods: {
        reveal (delay) {
            this.splitText()


            let tl = gsap.timeline()
            const tme = 0.7
            const stagger = "-=0.4"
            tl.fromTo('.page-news-detail .block-article-masthead .copy',
                {
                    opacity: 0
                },
                {
                    opacity: 1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                delay
            )
            tl.fromTo('.page-news-detail .block-article-masthead .featured',
                {
                    x: 100,
                    opacity: 0
                },
                {
                    x: 0,
                    opacity: 1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                delay
            )
            tl.fromTo('.page-news-detail .block-article-masthead .circle-black',
                {
                    scale: 0
                },
                {
                    scale: 1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                stagger
            )
            tl.fromTo('.page-news-detail .block-article-masthead .circle-gray',
                {
                    scale: 0
                },
                {
                    scale: 1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                stagger
            )
            tl.fromTo('.page-news-detail .block-article-masthead .circle-black .txt',
                {
                    x: -100,
                    opacity: 0,
                },
                {
                    x: 0,
                    opacity: 1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                stagger
            )
            tl.fromTo(this.splitTitle.words,
                {
                    x: -100,
                    opacity: 0,
                    letterSpacing: '0.02rem'
                },
                {
                    x: 0,
                    opacity: 1,
                    duration: 0.7,
                    ease: "power3",
                    stagger: 0.06,
                    letterSpacing: '0'
                },
                stagger
            )
            tl.fromTo('.page-news-detail .block-article-masthead .share-strip',
                {
                    y: -20,
                    opacity: 0
                },
                {
                    y: 0,
                    opacity: 1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                stagger
            )
            tl.fromTo('.page-news-detail .block-article-masthead .share-strip .circle',
                {
                    scale: 0
                },
                {
                    scale: 1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                stagger
            )
        },
        splitText() {
            this.splitTitle = new SplitText('.page-news-detail .block-article-masthead .title h1', {
                type:'words'
            })
        },
    }
}
</script>
