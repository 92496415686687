<template>
    <section v-if="json" class="page-home">
        <landing :json="json.landingPage" v-if="section==1 || isMobile" class="section1"></landing>
        <studio-news :json="json.studioNews" v-if="showStudioNews" class="section2"></studio-news>
        <in-the-press :json="json.inThePress" v-if="inThePressHasContent && (section==3 || section==4 || isMobile)" class="section3"></in-the-press>
        <home-nav v-model:section="section" :json="json" v-if="!isMobile"></home-nav>
    </section>
</template>

<script>
    import axios from 'axios'
    import Landing from '@/components/blocks/home/Landing'
    import StudioNews from '@/components/blocks/home/StudioNews'
    import InThePress from '@/components/blocks/home/InThePress'
    import HomeNav from '@/components/blocks/home/HomeNav'
    import gsap, {
        Power2
    } from '@/assets/js/gsap/gsap-core';

    export default {
        name: "Home",
        components: {
            Landing,
            StudioNews,
            InThePress,
            HomeNav
        },
        data() {
            return {
                json: null,
                section: 1,
                prevSection: 1,
                lastScrollTime: Number,
                isMobile: Boolean
            }
        },
        mounted() {
            document.documentElement.classList.add('no-overflow-desktop');
            window.addEventListener('resize', this.onResize);
            this.onResize();

            axios.get((`${window.config.apiroot}home${window.config.apiext}`))
                .then((res) => {
                    this.json = res.data;
                    document.body.addEventListener("wheel", this.onScroll);
                }).catch(() => {});
        },
        methods: {
            onScroll(e) {
                if (window.innerWidth < 850) return;

                // prevents changing sections too fast
                if (this.scrollThrottled()) return;

                this.prevSection = this.section;

                let scrollingDown = e.deltaY > 0;
                let section1Upward = this.section == 1 && !scrollingDown;
                let section3ToFooter;
                let footerToSection3;

                if (this.inThePressHasContent) {
                    section3ToFooter = this.section == 3 && scrollingDown;
                    footerToSection3 = this.section == 4 && !scrollingDown;
                } else {
                    section3ToFooter = this.section == 2 && scrollingDown;
                    footerToSection3 = this.section == 3 && !scrollingDown;
                }

                // console.log(section1Upward, section3ToFooter, footerToSection3);
                

                if (!section3ToFooter && !footerToSection3 && !section1Upward) {
                    gsap.to('.section' + this.prevSection, {
                        opacity: 0,
                        duration: 0.45,
                        ease: Power2.easeIn,
                    });
                    gsap.to('.section' + this.prevSection, {
                        y: scrollingDown ? '-60vh' : '60vh',
                        duration: 0.5,
                        ease: Power2.easeIn,
                        onComplete: () => {
                            this.updateSectionNumber(scrollingDown);
                        }
                    });
                } else {
                    this.updateSectionNumber(scrollingDown);
                }

                this.showHideFooter();

            },
            updateSectionNumber(scrollingDown) {
                this.section = scrollingDown ? this.section += 1 : this.section -= 1;
                if (this.section < 1) this.section = 1;
                if (this.section > 4) this.section = 4;

                if (!this.inThePressHasContent) {
                    if (this.section > 3) this.section = 3;
                }
                
            },
            showFooter() {
                document.querySelector('#footer').style.transform = 'translateY(-100%)';
                document.querySelector('.cap--main').style.transform = 'translateY(-20%)';
            },
            hideFooter() {
                document.querySelector('#footer').style.transform = 'translateY(0%)';
                document.querySelector('.cap--main').style.transform = 'translateY(0%)';
            },
            scrollThrottled() {
                

                if (this.inThePressHasContent) {
                    if (this.section == 4) return false;
                } else {
                    if (this.section == 3) return false;
                }

                let curTime = new Date().getTime();
                if (curTime - this.lastScrollTime < 1500) {
                    return true;
                }
                this.lastScrollTime = curTime;
                return false;
            },
            showHideFooter() {
                
                let lastSectionWithContent = this.inThePressHasContent ? 4 : 3;
                // console.log(lastSectionWithContent, this.section);
                
                if (this.section == lastSectionWithContent) {
                    this.showFooter();
                } else {
                    this.hideFooter();
                }
            },
            onResize() {
                this.isMobile = window.innerWidth < 850;

                // force removal of transforms on mobile
                if (this.isMobile) {
                    this.hideFooter();
                    this.section = 1;
                } else {
                    // Force scroll to reset to top for desktop
                    // Fixes weird issue where footer stays shifted up when mobile view is scrolled down to footer and the resizing to desktop
                    window.scrollTo(0, 0);
                }
            }
        },
        computed: {
            inThePressHasContent() {
                return this.json.inThePress.slides && this.json.inThePress.slides.length;
            },
            showStudioNews() {
                if (this.inThePressHasContent) {
                    return this.section == 2 || this.isMobile;
                } else {
                    return this.section == 2 || this.section == 3 || this.isMobile;
                }
            }
        },
        watch: {
            section() {
                this.showHideFooter();
            }
        },
        beforeUnmount() {
            document.body.querySelector('#footer').classList.remove('footer-home');
            document.documentElement.classList.remove('no-overflow-desktop');
            document.body.removeEventListener("wheel", this.onScroll);
            window.removeEventListener('resize', this.onResize);
            this.hideFooter()
        }
    }
</script>

<style lang="scss">
    @import "../../../src/assets/scss/site/pages/home.scss";
</style>