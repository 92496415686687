<template>
    <section v-if="json" class="page-careers global-fonts">
        <div class="cap">
            <template v-for="(block, index) in json.blocks" :key="`block-${index}`">
                <Header v-if="block.type=='header'" :block="block" />
                <Quote v-else-if="block.type=='quote-one' || block.type=='quote-two'" :block="block"/>
                <Slider v-else-if="block.type=='slider-one' || block.type == 'slider-two' || block.type == 'slider-three'  || block.type == 'slider-four'" :block="block"/>
                <Founders v-else-if="block.type=='founders'" :block="block"/>
                <MeetTheTeam v-else-if="block.type=='meet-the-team'" :block="block"/>
                <CareersBlock v-else-if="block.type=='careers'" :block="block"/>
            </template>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import Header from '@/components/blocks/Header'
import Quote from '@/components/blocks/Quote'
import Slider from '@/components/blocks/Slider'
import Founders from '@/components/blocks/Founders'
import MeetTheTeam from '@/components/blocks/MeetTheTeam'
import CareersBlock from '@/components/blocks/Careers'
export default {
    name: "Careers",
    components: {
        Header,
        Quote,
        Slider,
        Founders,
        MeetTheTeam,
        CareersBlock,
    },
    data () {
        return {
            window: window,
            json: null,
        }
    },
    created () {
        // TODO RYAN - FYI - GREENHOUSE API DATA IS HERE
        // you'll likely need to massage the info, perhaps break into department first if not already
        // if you wish to test with a different API feed, search greenhouseUrl in main.js and change the url there
        //console.log('ALL GREENHOUSE API data', window.config.greenhouse)
    },
    mounted () {
        axios.get((`${window.config.apiroot}careers${window.config.apiext}`))
            .then((res) => {
                this.json = res.data
                this.reveal()
            }).catch(() => {
        })
    },
    methods: {
        reveal () {

        },
    }
}
</script>
