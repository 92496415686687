<template>
    <div class = "tnm__header" v-sr="{ beforeReveal: triggerAnimations, distance: 0 }">
        <div class = "tnm__header__holder light" ref = "holder">
            <div class = "tnm__graybg" ref = "graybg"></div>
            <div class = "tnm__header__text">
                <h1 ref = "title">{{ block.title }}</h1>
                <p ref = "description">{{ block.description }}</p>
                <span ref = "rule" class = "tnm__header__text__rule tnm__rule"></span>
            </div>
            <div class = "tnm__header__image-holder">
                <div class = "tnm__header__image-holder__image" ref = "image" :style="block.bgWithStyle">
                    <div class = "tnm__header__image-holder__overlay" ref = "image">
                        <lottie-animation trigger = "function" ref = "frame" :path="require('@/assets/lottie/header.json')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { SplitText } from '@/assets/js/gsap/SplitText';
import gsap, { Power3 } from '@/assets/js/gsap/gsap-core';
export default {
    name: "Header",
    data() {
        return {
            splitTitle: null,
            splitDescription: null,
        }
    },
    props: [
        'block'
    ],
    mounted() {
        this.$nextTick(this.splitText);
        this.tl = gsap.timeline();
    },
    updated() {
        this.$nextTick(this.splitText);
    },
    methods: {
        splitText() {
            this.splitTitle = new SplitText(this.$refs.title, {
                type:'words'
            });
            this.splitDescription = new SplitText(this.$refs.description, {
                type:'words'
            });
        },
        triggerAnimations() {
            this.tl.from(this.$refs.graybg, {duration: 1.5, opacity: 0, height: 0, ease: "power3", clearProps: "all"}, 0)
                .from(this.splitTitle.words, {duration: 0.5, opacity: 0, x: -100, ease: "power3", stagger: 0.03, letterSpacing: '.5rem'}, 0.5)
                .from(this.splitDescription.words, {duration: 0.5, opacity: 0, x: -10, ease: "power3", stagger: 0.05 }, 1)
                .from(this.$refs.rule, {duration: 0.5, opacity: 0, x: -100, ease: "power3", clearProps: "all"}, 1.8)
                .from(this.$refs.image, {duration: 1, opacity: 0, x: 200, ease: Power3.easeInOut }, 1.2)
                .add(() => { this.$refs.frame.playAnimation(1); this.splitTitle.revert(); this.splitDescription.revert(); }, '-=0.5')
        }
    }
}
</script>
