<template>
    <div class = "tnm__meet-the-team" v-sr="{ beforeReveal: triggerAnimations, distance: 0 }">
        <div class = "tnm__meet-the-team__title" ref = "header">
            <h2 ref = "title">{{ block.title }}</h2>
            <span class = "tnm__meet-the-team__title__rule tnm__rule" ref = "rule"></span>
        </div>
        <div ref = "controls">
            <team-controls :activeDepartment="activeDepartment" :departments="departmentsWithMembers" @newDepartment="newDepartment" />
        </div>
        <div class = "tnm__meet-the-team__team" ref = "team">
            <team v-for="(item, index) in activeDepartmentTeam" :key="`item-${index}`" :item="item" :activeDepartment="activeDepartment" />
        </div>
        <div class = "tnm__meet-the-team__more" ref = "more" v-if="needMore" @click="limit+=4">
            <div class = "tnm__meet-the-team__more__button" ref = "more" @mouseover="triggerButtonEnter('more')" @mouseleave="triggerButtonExit('more')">
                <lottie-animation trigger = "function" ref = "more__button" :path="require('@/assets/lottie/view-more.json')" />
            </div>
        </div>
    </div>
</template>
<script>
import Team from '@/components/pieces/Team.vue';
import TeamControls from '@/components/pieces/TeamControls.vue';

import { SplitText } from '@/assets/js/gsap/SplitText';
import gsap from '@/assets/js/gsap/gsap-core';
export default {
    name: "MeetTheTeam",
    components: {
        Team,
        TeamControls
    },
    data() {
        return {
            activeDepartment: {
                id: 'all',
                name: 'View All Departments'
            },
            limit: 8,
        }
    },
    props: [
        'block'
    ],
    created () {
    },
    mounted() {
        this.tl = gsap.timeline();
        this.$nextTick(this.splitText);
    },
    updated() {
        this.$nextTick(this.splitText);
    },
    computed: {
        needMore() {
            var count = 0;
            this.block.team.forEach(e => {
                if (e['department'] == this.activeDepartment.id || this.activeDepartment.id == 'all') {
                    count++;
                }
            });
            if(count > this.limit) {
                return true;
            }
            return false;
        },
        activeDepartmentTeam() {
            var team = [];
            this.block.team.forEach(e => {
                if (e.department == this.activeDepartment.id || this.activeDepartment.id == 'all') {
                    if(team.length < this.limit) {
                        team.push(e);
                    }
                }
            });
            return team;
        },
        departmentsWithMembers() {
            var departmentsWithMembers = [];
            this.block.departments.forEach(d => {
                for(var x = 0; x < this.block.team.length; x++) {
                    if (this.block.team[x].department == d['id']) {
                        if(!departmentsWithMembers.includes(d['id'])) {
                            departmentsWithMembers.push(d);
                            break;
                        }
                    }
                }
            });
            return departmentsWithMembers;
        }
    },
    methods: {
        newDepartment(data) {
            this.activeDepartment = {
                id: data.id,
                name: data.name,
            }
            this.limit=8;
        },
        triggerButtonEnter(btn) {
            this.$refs[btn].classList.add('active');
            this.$refs[btn + '__button'].playAnimation(1);
        },
        triggerButtonExit(btn) {
            this.$refs[btn].classList.remove('active');
            this.$refs[btn + '__button'].playAnimation(-1);
        },
        splitText() {
            this.splitTitle = new SplitText(this.$refs.title, { type: 'words' });
        },
        triggerAnimations() {
            this.tl
                .from(this.splitTitle.words, {duration: 0.7, opacity: 0, x: -100, ease: "power3", stagger: 0.06, letterSpacing: '.5rem'}, 0)
                .from(this.$refs.rule, {duration: 0.5, opacity: 0, x: -100, ease: "power3", clearProps: "all"}, '-=0.1')
                .from(this.$refs.controls, {duration: 0.5, opacity: 0, y: 100, ease: "power3", clearProps: "all"}, '-=0.1')
                .from(this.$refs.team, {duration: 1, opacity: 0, y: 100, ease: "power3", clearProps: "all"}, '-=0.1')
                .add(() => { this.splitTitle.revert(); }, '+=0')
        }
    }
}
</script>