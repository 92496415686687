<template>
    <div class="block block-video-games" >
        <div v-if="json.title" class="optional-block-subtitle rte no-max-width" >
            <h2 v-html="json.title"></h2>
            <div class="global-line"></div>
        </div>
        <div v-if="json.bg" class="bg" v-sr>
            <img :src="json.bg"/>
            <div class="icon" v-sr>
                <div @mouseenter="$refs.btn__overlay.playAnimation(1)" @mouseleave="$refs.btn__overlay.playAnimation(-1)" @click="$store.commit('openPopup', {'type':'video','videoId':`${json.videoId}`})" class="icon2 noselect">
                    <lottie-animation ref = "btn__overlay" trigger = "function" :path="require('@/assets/lottie/play-button.json')" />
                </div>
            </div>
            <div class="navigation-frame noselect">
                <div class="navigation-frame2">
                    <div class="website" >
                        <a :href="`https://youtube.com/watch?v=${json.videoId}`" target="_blank">
                            <button class="website-button">
                                <div class="website-l">
                                    watch on youtube
                                </div>
                                <div class="website-r">
                                    <inline-svg :src="require('@/assets/images/global/icon-youtube.svg')"></inline-svg>
                                </div>
                            </button>
                        </a>
                    </div>
                </div>

            </div>
        </div>
        <div class="show-mobile bottom-button" v-sr>
            <div class="scrim"></div>
            <div class="website" >
                <a :href="`https://youtube.com/watch?v=${json.videoId}`" target="_blank">
                    <button class="website-button">
                        <div class="website-l">
                            watch on youtube
                        </div>
                        <div class="website-r">
                            <inline-svg :src="require('@/assets/images/global/icon-youtube.svg')"></inline-svg>
                        </div>
                    </button>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
//import Caption from '@/components/blocks/generic/sub/Caption'
export default {
    name: "Video",
    components: {
        //Caption
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
    },

}
</script>
