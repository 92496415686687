<template>
    <div class="tnm__meet-the-team__team__member" @mouseenter="triggerTeamEnter" @mouseleave="triggerTeamExit" ref = "team" @click="$store.commit('openPopup', {'type':'bio','item':item})">
        <div class = "tnm__meet-the-team__team__member__image" :style="item.bgWithStyle"></div>
        <h3 class = "default-name" ref = "defaultName">{{ item.name }}</h3>
        <div class = "tnm__meet-the-team__team__member__info" ref = "info">
            <h3 ref = "name">{{ item.name }}</h3>
            <div class = "tnm__meet-the-team__team__member__info__title" ref = "title">{{ item.title }}</div>
        </div>
        <div class = "tnm__meet-the-team__team__member__button" @mouseenter="triggerButtonEnter" @mouseleave="triggerButtonExit" ref = "button">
            <inline-svg ref = "button__svg" :src="require('@/assets/images/global/small-btn.svg')"></inline-svg>
        </div>
    </div>
</template>
<script>
import gsap from '@/assets/js/gsap/gsap-core';
export default {
    name: "Team Member",
    props: ['item', 'activeDepartment'],
    mounted() {
            gsap.fromTo(this.$refs.team, { opacity: 0, y: -100, }, { duration: 0.5, opacity: 1, y: 0, ease: 'power3' });

            this.tlOff = gsap.timeline({ paused: true });
            this.tlOff
                .to(this.$refs.info, {duration: 0, pointerEvents: 'none'}, 0 )
                .fromTo(this.$refs.team, {zIndex: 500, scale: 1.1, filter: 'none' }, {  duration: 0.2, zIndex: 0, scale: 1, filter: 'grayscale(100%)', ease: "power3"}, 0)
                .fromTo(this.$refs.button, { opacity: 1, y: 0, }, {duration: 0.2, opacity: 0, y: 100, ease: "power3"}, '-=.1')
                .fromTo(this.$refs.title, { opacity: 1, y: 0, }, {duration: 0.2, opacity: 0, y: 100, ease: "power3"}, '-=.1')
                .fromTo(this.$refs.name, { opacity: 1, y: 0, }, {duration: 0.2, opacity: 0, y: 100, ease: "power3"}, '-=0')
                .fromTo(this.$refs.info, { opacity: 1, y: 0, }, {duration: 0.2, opacity: 0, y: 100, ease: "power3"}, '-=.3')
                .fromTo(this.$refs.defaultName, { y: 100, opacity: 0 }, {duration: 0.1, opacity: 1, y: 0, ease: "power3"}, '-=.3')
                .add(() => { this.$refs.team.classList.remove('active'); }, '+=0')

            this.tlOn = gsap.timeline({ paused: true });
            this.tlOn
                .add(() => { this.$refs.team.classList.add('active'); })
                .fromTo(this.$refs.defaultName, { y: 0, opacity: 1 }, {duration: 0.1, opacity: 0, y: 100, ease: "power3"}, 0)
                .fromTo(this.$refs.team, { zIndex: 0, scale: 1, filter: 'grayscale(100%)' }, {duration: 0.5, zIndex: 500, scale: 1.1, filter: 'none', ease: "power3"}, 0)
                .fromTo(this.$refs.info, { opacity: 0, y: 100, pointerEvents: 'none', }, {duration: 0.2, opacity: 1, y: 0, ease: "power3", pointerEvents: 'all', }, '-=.5')
                .fromTo(this.$refs.name, { opacity: 0, y: 100, }, {duration: 0.2, opacity: 1, y: 0, ease: "power3"}, '-=.4')
                .fromTo(this.$refs.title, { opacity: 0, y: 100, }, {duration: 0.2, opacity: 1, y: 0, ease: "power3"}, '-=.1')
                .fromTo(this.$refs.button, { opacity: 0, y: 100, }, {duration: 0.2, opacity: 1, y: 0, ease: "power3"}, '-=.1')
    },
    watch: {
        activeDepartment: function() {
            gsap.fromTo(this.$refs.team, { opacity: 0, y: -100, }, { duration: 0.5, opacity: 1, y: 0, ease: 'power3' });
        }
    },
    methods: {
        triggerTeamEnter() {
            if(window.innerWidth > 850) {
                this.tlOff.pause();
                this.tlOn.restart();
            }
        },
        triggerTeamExit() {
            if(window.innerWidth > 850) {
                this.tlOn.pause();
                this.tlOff.restart();
            }
        },
        triggerButtonEnter() {
            if(window.innerWidth > 850) {
                var tl = gsap.timeline();
                tl.fromTo(this.$refs.button__svg.$el, { x: 0, opacity: 1, }, { duration: 0.2, x: 10, opacity: 0, ease: "power3" }, 0)
                    .fromTo(this.$refs.button__svg.$el, { x: -40, opacity: 0, }, { duration: 0.2, x: 0, opacity: 1, ease: "power3" }, '+=0')
            }
        },
        triggerButtonExit() {
            //
        },
    }
}
</script>
