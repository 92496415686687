//REACTIVE
import { createStore } from 'vuex'

export const store = createStore({
    state () {
        return {
            smallHeader: false,
            popupOpen: false,
            popupObject:{},
            triggerTransitionOverlay: Math.random(),
            routerNext: null,
            mobileCloseToggler:'',
            largeSidebar:false,
            didInitialSiteTransition:false,
        }
    },
    mutations: {
        setLargeSidebar (state, val) {
            state.largeSidebar = (val)
        },
        setSmallHeader (state, val) {
            state.smallHeader = (val)
        },
        openPopup (state, obj) {
            state.popupObject = obj
            state.popupOpen = true
        },
        closePopup (state) {
            state.popupObject =
            state.popupOpen = false
        },
        triggerTransitionOverlay (state) {
            state.triggerTransitionOverlay = Math.random()
        },
        routerNext(state, next) {
            state.routerNext = next
        },
        closeMobileNav(state) {
            state.mobileCloseToggler = Math.random()
        },
        setDidInitialSiteTransition(state) {
            state.didInitialSiteTransition = true
        }
    }
})
export default store
