<template>
    <div class="game" v-sr="{ beforeReveal: triggerAnimations, distance: 0 }">
        <div class="top" >
            <div class="sr-rhook featured">
                <div class="show-desktop sr-scaledown bgimg" :style="item.bgWithStyle"></div>
                <img class="show-mobile" v-sr :src="item.bg"/>
            </div>
            <div class="release" >
                <div class="lines">
                    <lottie-animation trigger="function" ref="gamelines" :path="require('@/assets/lottie/header.json')" />
                </div>
                <div class="release2" >
                    <div class="sr-lhook lbl" v-html="item.label" ></div>
                    <div class="sr-lhook month" v-html="item.month" ></div>
                    <div class="sr-lhook year" v-html="item.year" ></div>
                    <div class="read-more-padder"></div>
                    <div class="sr-lhook read-more">
                        <router-link :to="item.link"
                                     @mouseenter="mouseEnter"
                                     @mouseleave="mouseLeave"
                        >
                            <div class="icon noselect" >
                                <div class="show-desktop">
                                    <lottie-animation ref = "btn__more__desktop" trigger="function" :path="require('@/assets/lottie/games-learn-more.json')" />
                                </div>
                                <div class="show-mobile">
                                    <lottie-animation ref = "btn__more__mobile" trigger="function" :path="require('@/assets/lottie/learn-more-mobile.json')" />
                                </div>

                            </div>
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
        <div class="bottom">
            <div class="cap-margin-padded-2x">
                <div class="rte">
                    <h2 v-html="item.title" ></h2>
                    <div class="description" v-html="item.description"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Games_GameInner",
    props: {
        item: {
            type: Object,
            required: true,
            default: null,
        },
        index:{
            type:Number
        }
    },
    mounted() {
        console.log(this.item,this.index)
    },
    methods:{
        triggerAnimations() {
            setTimeout(function() {
                this.$refs['gamelines'].playAnimation(1)
            }.bind(this),500)

        },
        mouseEnter() {
            this.$refs.btn__more__desktop.playAnimation(1)
            this.$refs.btn__more__mobile.playAnimation(1)

        },
        mouseLeave() {
            this.$refs.btn__more__desktop.playAnimation(-1)
            this.$refs.btn__more__mobile.playAnimation(-1)
        },
    }
}
</script>
