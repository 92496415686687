<template>
    <div ref = "slide" class="tnm__slider__body__slide swiper-slide">
        <div v-if="item.bgWithStyle" class="tnm__slider__body__slide__image" :style="item.bgWithStyle" ref = "image"></div>
        <div class = "tnm__slider__body__slide__inner">
            <div class = "tnm__slider__body__slide__content">
                <h2 v-if="item.title">{{ item.title }}</h2>
                <span class = "tnm__slider__rule tnm__rule"></span>
                <p v-if="item.description">{{ item.description }}</p>
            </div>
        </div>
    </div>
</template>
<script>
//import { gsap } from "gsap";
export default {
    name: "Slide",
    props: ['activeSlide', 'num', 'item'],
    mounted() {
    },
    methods: {
    }
}
</script>
