<template>
    <div class="subblock-caption" >
        <div :class="[(caption) ? 'show' : 'hide']" class="subblock-caption2 cap-padded-2x">
            <div class="caption" v-html="caption"></div>
            <div class="global-line"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Caption",
    props: {
        caption: {
            type: String,
            required: false,
            default:'',
        },
    },

}
</script>
