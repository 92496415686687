<template>
    <section v-if="json" class="page-news" v-sr="{ beforeReveal: triggerAnimations, distance: 0 }">
        <div class="featured">
            <div class = "featured__overlay" ref = "overlay">
                <fuzzy-circle ref = "featured__circle" type="big-gray"/>
                <div class = "featured__overlay__ornament">
                    <lottie-animation ref = "ornament" trigger = "function" :path="require('@/assets/lottie/news-landing.json')" />
                </div>
                <div class = "featured__overlay__text" ref = "featured__story">Featured Story</div>
            </div>
            <div class = "featured__banner" ref = "banner">
                <img :src="json.header.bg" ref = "banner__image"/>
                <div class = "featured__title">
                    <h2 ref = "title" v-html="json.header.title"></h2>
                    <link-content class="inner" :href="json.header.href" target="_self">
                        <div class="read-btn" ref = "banner__btn">
                            <lottie-animation trigger="hover" :path="require('@/assets/lottie/read-button.json')" />
                        </div>
                    </link-content>
                </div>
            </div>
        </div>

        <div class="latest" v-if="json.latestArticles.length">
            <div class="latest__item" v-for="(article, index) in json.latestArticles" :key="`latest-${index}`" :ref="`latest-${index}`" @mouseenter="startHover(index)" @mouseleave="endHover(index)">
                <lottie-animation trigger="function" :path="require('@/assets/lottie/studio-news-ornament.json')" :ref="`ornament-${index}`" />
                <link-content class="inner" :href="article.href" target="_self">
                    <div class="inner__image" :style="article.bgWithStyle"></div>
                    <div class="inner__content">
                        <h5>{{ article.title }}</h5>
                        <div>
                            <p v-if="article.author" class="txt-author">{{ article.author }}</p>
                            <p class="txt-date" v-html="gh.dateToMONDDYYYY(article.dateNews,'long','/')"></p>
                        </div>
                    </div>
                    <div class="read-btn">
                        <lottie-animation trigger="hover" :path="require('@/assets/lottie/read-button.json')" />
                    </div>
                </link-content>

            </div>
        </div>

        <div class="cap-margin-padded-2x">

            <div v-if="activeArticles.length" class="block-wrapper-related-articles">
                <ArticleRelated :json="activeArticles" :title="false"></ArticleRelated>
            </div>

            <div class = "more" ref = "more" v-if="needMore" @click="limit+=2">
                <div class = "more__button" ref = "more__btn">
                    <lottie-animation trigger="hover" ref = "more__button" :path="require('@/assets/lottie/view-more.json')" />
                </div>
            </div>

        </div>

    </section>
</template>

<script>
    import axios from 'axios'
    import LinkContent from '@/components/pieces/LinkContent'
    import ArticleRelated from '@/components/blocks/generic/ArticleRelated'
    import gsap from '@/assets/js/gsap/gsap-core';
    import gh from '@/helpers/gh'

    export default {
        name: "News",
        components: {
            LinkContent,
            ArticleRelated
        },
        data() {
            return {
                window: window,
                json: null,
                gh:gh,
                limit: 8,
            }
        },
        mounted() {
            this.tl = gsap.timeline();
            axios.get((`${window.config.apiroot}news${window.config.apiext}`))
                .then((res) => {
                    this.json = res.data
                }).catch(() => {})
        },
        computed: {
            needMore() {
                var count = 0;
                this.json.relatedArticles.forEach(() => {
                    count++;
                });
                if(count > this.limit) {
                    return true;
                }
                return false;
            },
            activeArticles() {
                var articles = [];
                this.json.relatedArticles.forEach(e => {
                    if(articles.length < this.limit) {
                        articles.push(e);
                    }
                });
                return articles;
            },
        },
        methods: {
            triggerAnimations() {
                this.tl
                    .from(this.$refs.overlay, {duration: 0.5, opacity: 0, y: 100, ease: "power3", clearProps: "all"}, 0)
                    .add(() => { this.$refs.featured__circle.playAnimation(); this.$refs.ornament.playAnimation(); }, 0)
                    .from(this.$refs.banner, {duration: 0.5, opacity: 0, x: 100, ease: "power3", clearProps: "all"}, '+=.2')
                    .from(this.$refs.banner__image, {duration: 0.5, opacity: 0, x: 100, ease: "power3", clearProps: "all"}, '-=.4')
                    .from(this.$refs.title, {duration: 0.5, opacity: 0, y: 100, ease: "power3", clearProps: "all"}, '-=.3')
                    .from(this.$refs.banner__btn, {duration: 0.5, opacity: 0, y: 100, ease: "power3", clearProps: "all"}, '-=.3')
            },
            startHover(index) {
                this.$refs[`latest-${index}`].classList.add('hover');
                this.positionOrnament(index);
                this.$refs[`ornament-${index}`].playAnimation(1);
            },
            endHover(index) {
                this.$refs[`latest-${index}`].classList.remove('hover');
                this.$refs[`ornament-${index}`].playAnimation(-1);
            },
            positionOrnament(index) {
                this.$refs[`ornament-${index}`].$el.style.left = (0 - this.$refs[`latest-${index}`].getBoundingClientRect().width*.16) + 'px';
                this.$refs[`ornament-${index}`].$el.style.top = (0 - this.$refs[`latest-${index}`].getBoundingClientRect().width*.205) + 'px';
                this.$refs[`ornament-${index}`].$el.style.width = (this.$refs[`latest-${index}`].getBoundingClientRect().width) + 'px';
                this.$refs[`ornament-${index}`].$el.style.height = (this.$refs[`latest-${index}`].getBoundingClientRect().width) + 'px';
            },
        }
    }
</script>

<style lang="scss">
    @import "../../../src/assets/scss/site/pages/news.scss";
</style>
