<template>
    <div class = "tnm__founders" v-sr="{ beforeReveal: triggerAnimations, distance: 0 }">
        <div class = "tnm__founders__title" ref = "header">
            <h2 ref = "title">{{ block.title }}</h2>
            <span class = "tnm__founders__title__rule tnm__rule" ref = "rule"></span>
        </div>
        <div class = "tnm__graybg dark tnm__founders__holder" ref = "founders">
            <founder v-for="(item, index) in block.founders" :key="`item-${index}`" :item="item"/>
        </div>
    </div>
</template>
<script>
import Founder from '@/components/pieces/Founder.vue';

import { SplitText } from '@/assets/js/gsap/SplitText';
import gsap from '@/assets/js/gsap/gsap-core';
export default {
    name: "Founders",
    components: {
        Founder
    },
    props: [
        'block'
    ],
    created () {
    },
    mounted() {
        this.tl = gsap.timeline();
        this.$nextTick(this.splitText);
    },
    updated() {
        this.$nextTick(this.splitText);
    },
    methods: {
        splitText() {
            this.splitTitle = new SplitText(this.$refs.title, { type: 'words' });
        },
        triggerAnimations() {
            this.tl
                .from(this.splitTitle.words, {duration: 0.7, opacity: 0, x: -100, ease: "power3", stagger: 0.06, letterSpacing: '.5rem'}, 0)
                .from(this.$refs.rule, {duration: 0.5, opacity: 0, x: -100, ease: "power3", clearProps: "all"}, '-=0.1')
                .from(this.$refs.founders, {duration: 1, opacity: 0, y: 100, ease: "power3", clearProps: "all"}, '-=0.1')
                .add(() => { this.splitTitle.revert(); }, '+=0')
        }
    }
}
</script>
