<template>
    <div v-show="beganReveal" class="block block-game-masthead">
        <div class="l">
            <div class="featured" >
                <div class="bgimg-outer" v-if="json.bgWithStyle">
                    <div class="lines-tl">
                        <inline-svg :src="require('@/assets/images/games-detail/lines-topleft-frame.svg')"></inline-svg>
                    </div>
                    <div class="bgimg" :style="json.bgWithStyle"></div>
                    <div class="video-btn noselect" @mouseenter="$refs.btn__overlay.playAnimation(1)" @mouseleave="$refs.btn__overlay.playAnimation(-1)" @click="$store.commit('openPopup', {'type':'video','videoId':`${json.videoId}`})" >
                        <lottie-animation ref = "btn__overlay" trigger = "function" :path="require('@/assets/lottie/play-button.json')" />
                    </div>
                </div>
                <div class="character" v-if="json.character">
                    <div class="character2">
                        <img :src="json.character"/>
                    </div>
                </div>

            </div>
            <div class="copy" >
                <div class="copybg" ></div>
                <div class="cap-padded">
                    <div class="rte-nosr no-max-width">
                        <h1 v-html="json.title"></h1>
                    </div>
                </div>
                <div class="preorder noselect"  >
                    <div class="preorder2" @mouseenter="$refs.btn__preorder_desktop.playAnimation(1)" @mouseleave="$refs.btn__preorder_desktop.playAnimation(-1)" >
                        <div class="bg-scrim"></div>
                        <lottie-animation ref = "btn__preorder_desktop" trigger = "function" :path="require('@/assets/lottie/games-preorder-desktop.json')" />
                    </div>
                </div>
                <div class="share-strip short">
                    <div class="vert" >
                        <div class="inner">
                            <div class="vert-title">SHARE</div>
                            <div class="icons">
                                <ul>
                                    <li>
                                        <a title="Facebook" :href="gh.getFacebookUrl(window.location.href)" target="_blank">
                                            <span class="socicon socicon-facebook"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Twitter" :href="gh.getTwitterUrl(window.location.href, document.title)" target="_blank">
                                            <span class="socicon socicon-twitter"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a title="LinkedIn" :href="gh.getLinkedInUrl(window.location.href)" target="_blank">
                                            <span class="socicon socicon-linkedin"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="r">
            <!-- GamesMastPlatforms is duplicated twice
            //the first has opacity 0
            //the second is show
            this is so in desktop I can stack the lines, while still preserving calculations
            -->
            <div class="platforms platforms-bg">
                <GamesMastPlatforms style="opacity: 0" :json="json"></GamesMastPlatforms>
            </div>
            <div class="platforms-lines">
                <lottie-animation ref="platforms_lines" trigger="function" :path="require('@/assets/lottie/quote-one.json')" />
            </div>
            <div class="platforms platforms-txt">
                <GamesMastPlatforms :json="json"></GamesMastPlatforms>
            </div>

        </div>


    </div>
</template>

<script>
//import ScrollReveal from 'scrollreveal'
import gh from '@/helpers/gh'
import { mapState } from 'vuex'
import gsap, { Power3 } from '@/assets/js/gsap/gsap-core'
import { SplitText } from '@/assets/js/gsap/SplitText'
import GamesMastPlatforms from '@/components/blocks/generic/sub/GamesMastPlatforms'
export default {
    name: "GameMast",
    components: {
        GamesMastPlatforms
    },
    data () {
        return {
            document:document,
            window:window,
            gh:gh,
            splitTitle:null,
            beganReveal:false,
        }
    },
    mounted() {
        if (this.didInitialSiteTransition) {
            setTimeout(function() {
                this.reveal(1)
            }.bind(this),100)
        }
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        }
    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition
        }),
    },
    watch: {
        didInitialSiteTransition() {
            this.reveal(0)
        }
    },
    methods: {
        reveal (delay) {


            this.splitText()

            let tl = gsap.timeline()
            const tme = 0.7
            const stagger = "-=0.35"
            //const stagger2 = "-=0.7"

            gsap.set('.block-game-masthead .lines-tl',{opacity:0})
            setTimeout(function() {
                this.$refs.platforms_lines.playAnimation(1)

                gsap.fromTo('.block-game-masthead .lines-tl',
                    {
                        opacity:0,
                    },
                    {
                        opacity:1,
                        duration:0.5,
                        delay:0.5
                    }
                )
            }.bind(this),(delay*1000))



            tl.fromTo('.page-games-detail .block-game-masthead .r',
                {
                    scaleX: 1,
                    opacity:0,
                },
                {
                    scaleX: 1,
                    opacity:1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                delay
            )
            //hard code this seperately
            tl.fromTo('.platforms-txt-inner .stagger-line',
                {
                    x: -100,
                    opacity: 0,
                },
                {
                    x: 0,
                    opacity: 1,
                    duration: 0.7,
                    ease: "power3",
                    stagger: 0.06,
                    letterSpacing: '0'
                },
                delay
            )
            tl.fromTo('.page-games-detail .block-game-masthead .bgimg-outer',
                {
                    width: 0,
                },
                {
                    width: '100%',
                    duration: tme,
                    ease: Power3.easeOut
                },
                delay
            )
            tl.fromTo('.page-games-detail .block-game-masthead .character2 img',
                {
                    //height: 0,
                    y:'100%',
                    opacity:0,
                },
                {
                    y:0,
                    opacity:1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                "-=1"
            )
            tl.fromTo('.page-games-detail .block-game-masthead .video-btn',
                {
                    scale:0,
                },
                {
                    scale:1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                "-=0.5"
            )

            tl.fromTo('.page-games-detail .block-game-masthead .copybg',
                {
                    width: 0,
                },
                {
                    width: '100%',
                    duration: tme,
                    ease: Power3.easeOut
                },
                "-=0"
            )


            tl.fromTo(this.splitTitle.words,
                {
                    x: -100,
                    opacity: 0,
                    letterSpacing: '0.02rem'
                },
                {
                    x: 0,
                    opacity: 1,
                    duration: 0.7,
                    ease: "power3",
                    stagger: 0.06,
                    letterSpacing: '0'
                },
                stagger
            )


            tl.fromTo('.page-games-detail .block-game-masthead .share-strip',
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                stagger
            )
            tl.fromTo('.page-games-detail .block-game-masthead .preorder',
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                    duration: tme,
                    ease: Power3.easeOut
                },
                stagger
            )


            this.beganReveal=true
            //gh.globalSectionInit()
            //ScrollReveal().sync()


        },
        splitText() {
            this.splitTitle = new SplitText('.page-games-detail .block-game-masthead h1', {
                type:'words'
            })
        },
        triggerAnimations() {

        }
    }
}
</script>
