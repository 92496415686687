<template>
    <div v-if="block.slides.length > 0">
        <slider-one v-if="block.type=='slider-one'" :block="block"/>
        <slider-two v-else-if="block.type=='slider-two'" :block="block"/>
        <slider-three v-else-if="block.type=='slider-three'" :block="block"/>
        <slider-four v-else-if="block.type=='slider-four'" :block="block"/>
        <slider-five v-else-if="block.type=='slider-five'" :block="block"/>
    </div>
</template>
<script>
import SliderOne from '@/components/pieces/slider/SliderOne.vue'
import SliderTwo from '@/components/pieces/slider/SliderTwo.vue'
import SliderThree from '@/components/pieces/slider/SliderThree.vue'
import SliderFour from '@/components/pieces/slider/SliderFour.vue'
import SliderFive from '@/components/pieces/slider/SliderFive.vue'
export default {
    name: "Slider",
    components: {
        SliderOne,
        SliderTwo,
        SliderThree,
        SliderFour,
        SliderFive
    },
    data() {
        return {
            activeSlide: 0,
            slider: null,
            resizeTimeout: null,
        }
    },
    props: [
        'block'
    ],
}
</script>
