<template>
    <div v-show="show" id="transition-overlay-outer" :class="[(doInitialSiteLoadReveal) ? 'intro' : 'interpage']">
        <div id="transition-overlay">
            <div v-if="doInitialSiteLoadReveal" id="transition-intro">
                <div class="intro intro-light">
                    <lottie-animation ref="intro__animation" trigger="function" :path="require('@/assets/lottie/intro-light.json')" />
                </div>
                <div class="intro intro-dark">
                    <lottie-animation ref="intro__animation_dark" trigger="function" :path="require('@/assets/lottie/intro-dark.json')" />
                </div>
            </div>
            <div v-else id="transition-page">
                <div class="trans trans-light">
                    <lottie-animation :key="rekeyPageAnimation" ref="page__animation" trigger="function" :path="require('@/assets/lottie/page-transition.json')" />
                </div>
                <div class="trans trans-dark">
                    <lottie-animation :key="rekeyPageAnimation" ref="page__animation__dark" trigger="function" :path="require('@/assets/lottie/page-transition-dark.json')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    import gsap, {
        Linear
    } from '@/assets/js/gsap'
    import gh from '@/helpers/gh'

    export default {
        name: "TransitionOverlay",
        data() {
            return {
                document: document,
                window: window,
                show: true,
                doInitialSiteLoadReveal: true,
                rekeyPageAnimation: Math.random(),
                devMode: window.config.localMode
            }
        },
        computed: {
            ...mapState({
                triggerTransitionOverlay: state => state.triggerTransitionOverlay,
                routerNext: state => state.routerNext,
            }),
        },
        watch: {
            triggerTransitionOverlay() {
                if (this.triggerTransitionOverlay) {
                    //if first load, just do transitionAnimations and bring off
                    if (this.doInitialSiteLoadReveal) {
                        this.doIntroAnimation()
                    } else {
                        this.bringOn1()
                    }

                }
            }
        },
        methods: {
            doIntroAnimation() {
                if (this.document.body.className.includes("theme-dark")) {
                    this.$refs.intro__animation_dark.playAnimation()
                } else {
                    this.$refs.intro__animation.playAnimation()
                }
                setTimeout(function() {
                    this.transitionAnimations2()
                }.bind(this), this.devMode ? 0 : 2500)
            },
            bringOn1() {
                //bring on transition screen
                this.show = true
                let tl = gsap.timeline({});
                tl.fromTo('#transition-overlay-outer', {
                        opacity: 0
                    }, {
                        opacity: 1,
                        duration: 0.35,
                        ease: Linear.easeNone,
                        onComplete: this.bringOn2
                    },
                    0
                )
                setTimeout(function() {
                    if (this.document.body.className.includes("theme-dark")) {
                        this.$refs.page__animation__dark.playAnimation()
                    } else {
                        this.$refs.page__animation.playAnimation()
                    }

                }.bind(this), 150)

            },
            bringOn2() {
                //change router
                this.routerNext()
                this.transitionAnimations()
            },
            transitionAnimations() {

                this.transitionAnimations2()
            },
            transitionAnimations2() {
                //init new content underneath invisibly
                gh.globalSectionInit()

                //once transition animations are done, bring off
                setTimeout(this.bringOff1, this.devMode ? 0 : 1000)
            },
            bringOff1() {
                //trigger didInitialSiteTransition for animation purposes in components
                this.$store.commit('setDidInitialSiteTransition')

                let tl = gsap.timeline({});
                tl.fromTo('#transition-overlay-outer', {
                        opacity: 1
                    }, {
                        opacity: 0,
                        duration: this.devMode ? 0 : 0.75,
                        ease: Linear.easeNone,
                        onComplete: this.bringOff2
                    },
                    0
                )
            },
            bringOff2() {
                this.show = false
                this.doInitialSiteLoadReveal = false
                this.window.config.routerInTransition = false
                this.rekeyPageAnimation = Math.random()
            }
        }
    }
</script>
