<template>
    <div v-if="slides.length" class="block block-in-the-press">
        <div v-if="json" class="block-in-the-press2">
            <div v-if="json.title" class="optional-block-subtitle rte no-max-width">
                <h2 v-html="json.title"></h2>
                <div class="global-line"></div>
            </div>
            <div :id="swiperHookId" class="swiper-hook" v-sr>
                <div class="swiper-hook2">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide"  v-for="(item, index) in slides" :key="`block-itpslides-${index}-${Math.random()}`">
                            <div class="game-item-outer" >
                                <a v-if="item.link.target==='_blank'" :href="item.link.url" target="_blank" >
                                    <InThePressInner :item="item" :index="index"></InThePressInner>
                                </a>
                                <router-link v-else :to="item.link.url" >
                                    <InThePressInner :item="item" :index="index"></InThePressInner>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="navigation-frame noselect">
                    <div class="navigation-frame2">
                        <div class="slides-btn slides-btn-next" ref="itpright" @mouseover="triggerButtonEnter('itpright')"
                             @mouseleave="triggerButtonExit('itpright')">
                            <div class="show-desktop">
                                <lottie-animation ref="itpright__button" trigger="function"
                                                  :path="require('@/assets/lottie/slider-right.json')"/>
                            </div>
                            <div class="show-mobile">
                                <inline-svg :src="require('@/assets/images/global/button-mobile-next.svg')"/>
                            </div>

                        </div>
                        <div class="slides-btn slides-btn-prev" ref="itpleft" @mouseover="triggerButtonEnter('itpleft')"
                             @mouseleave="triggerButtonExit('itpleft')">
                            <div class="show-desktop">
                                <lottie-animation ref="itpleft__button" trigger="function"
                                                  :path="require('@/assets/lottie/slider-left.json')"/>
                            </div>
                            <div class="show-mobile">
                                <inline-svg :src="require('@/assets/images/global/button-mobile-prev.svg')"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
import 'swiper/swiper-bundle.min.css'

Swiper.use([Navigation, Pagination, Autoplay])
//import gsap, {Power3} from '@/assets/js/gsap'
import InThePressInner from '@/components/blocks/generic/sub/InThePressInner'
export default {
    name: "InThePress",
    components: {
        InThePressInner
    },
    data () {
        return {
            slider: null,
            swiperHookId: null,
            swiperHookSel: null,
            currentSlide: 1,
            slides:[],
        }
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
    },
    mounted () {
        this.swiperHookId = 'fs-' + Date.now()
        this.swiperHookSel = '#' + this.swiperHookId + " .swiper-hook2"

        this.slides = []
        if (this.json.slides && this.json.slides.length>1) {
            for (let i=0;i<10;i++) {
                this.slides = this.slides.concat(this.json.slides)
            }
        }
        setTimeout(this.init, 500)

    },
    methods: {
        init () {
            this.slider = new Swiper(this.swiperHookSel, {
                init: false,
                speed: 500,
                grabCursor: true,
                loop: false,
                preventClicks: false,
                preventClicksPropagation: false,
                navigation: {
                    nextEl: '.block-in-the-press .slides-btn-next',
                    prevEl: '.block-in-the-press .slides-btn-prev'
                },
                breakpoints: {
                    850: {
                        slidesPerView: 3,
                        spaceBetween: 100,
                        centeredSlides: true,
                        initialSlide:5,
                    },
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 50,
                        centeredSlides: false,
                        initialSlide:0,
                    },
                }
            })
            this.slider.on('slideChangeTransitionStart', function (e) {
                console.log('slideChangeTransitionStart',e)
            }.bind(this))
            this.slider.on('slideChangeTransitionInit', function (e) {
                console.log('slideChangeTransitionInit',e)
            }.bind(this))

            setTimeout(this.sliderInit2, 100)
        },
        sliderInit2 () {
            this.slider.init()
        },
        triggerButtonEnter (btn) {
            this.$refs[btn].classList.add('active');
            this.$refs[btn + '__button'].playAnimation(1);
        },
        triggerButtonExit (btn) {
            this.$refs[btn].classList.remove('active');
            this.$refs[btn + '__button'].playAnimation(-1);
        },
    }
}
</script>
