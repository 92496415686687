<template>
    <div id="container" :class="(isLargeSidebar) ? 'sidebar-open' : 'sidebar-closed'">
        <TopCTAs/>
        <Header/>
        <div id="main">
            <Sidebar/>
            <div class="main-content">
                <div class="cap cap--main">
                    <router-view :key="$route.fullPath"/>
                </div>
                <Footer/>
            </div>
        </div>

        <Popup/>
        <TransitionOverlay/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from './components/framework/Header'
import Footer from './components/framework/Footer'
import Popup from './components/framework/Popup'
import TransitionOverlay from './components/framework/TransitionOverlay'
import { detect } from 'detect-browser'
import { isMobile } from 'mobile-device-detect'
import Sidebar from "./components/framework/Sidebar"
import TopCTAs from "./components/framework/TopCTAs";
export default {
    name: 'App',
    components: {
        Header,
        TopCTAs,
        Sidebar,
        Footer,
        Popup,
        TransitionOverlay
    },
    data() {
        return {
            browser:detect(),
            document:document,
            window:window,
            isLargeSidebar:false,
        }
    },
    computed: {
        ...mapState({
            largeSidebar: state => state.largeSidebar,
        }),
    },
    watch: {
        largeSidebar() {
            this.isLargeSidebar = this.largeSidebar
        },
    },
    mounted() {
        if (this.browser) {
            //add browser
            this.document.body.className += ' browser-'+this.browser.name

            //add device
            if (isMobile) {
                this.document.body.className += ' device-mobile'
            } else {
                this.document.body.className += ' device-notmobile'
            }

            //add dark mode theme
            if (this.window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.document.body.className += ' theme-dark'
                //this.document.body.className += ' theme-light'
            } else {
                this.document.body.className += ' theme-light'
            }
        }

    },
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>
