<template>
    <div :class="'tnm__contactform__form__field ' + field.width + ' ' + (error == true ? 'invalid' : '')">
        <span class = "tnm__contactform__form__field__label">{{ field.label }} <span class = "required"><span v-if="field.validation == 'required' || field.validation == 'email' || field.validation == 'date'">*</span></span></span>
        <template v-if="field.type=='text'">
            <input type = "text" :name="field.name" v-model="value" @input="validation__message=null" @blur="validate"/>
        </template>
        <template v-else-if="field.type=='email'">
            <input type = "text" :name="field.name" v-model="value" @blur="validate"/>
        </template>
        <template v-else-if="field.type=='phone'">
            <input type = "text" :name="field.name" v-model="value" @blur="validate" v-maska="'(###) ###-####'"/>
        </template>
        <template v-else-if="field.type=='country'">
            <Dropdown v-model="value" :options="countries" optionLabel="label" placeholder="Select a country" />
        </template>
        <template v-else-if="field.type=='dropdown'">
            <Dropdown v-model="value" :options="field.options" :placeholder="'Select a ' + field.label" />
        </template>
        <template v-else-if="field.type=='date' && date">
            <div class = "tnm__contactform__form__field__date">
                <div class = "tnm__contactform__form__field__date__inner">
                    <Dropdown v-model="date.month" :options="months" optionLabel="label" placeholder="Select a month" @change="checkDate" />
                </div>
                <div class = "tnm__contactform__form__field__date__inner">
                    <Dropdown v-model="date.day" :options="days" placeholder="Select a day" @change="checkDate" />
                </div>
                <div class = "tnm__contactform__form__field__date__inner">
                    <Dropdown v-model="date.year" :options="years" placeholder="Select a year" @change="checkDate" />
                </div>
            </div>
        </template>
        <template v-else-if="field.type=='textarea'">
            <textarea type = "text" :name="field.name" v-model="value" @blur="validate"></textarea>
        </template>
        <template v-else-if="field.type=='upload'">
            <input type="file" ref = "upload" @change="uploadFile" style="display:none"/>
            <div class = "tnm__contactform__form__field__upload__button" @click="$refs.upload.click();"> 
                <lottie-animation ref = "upload__btn" trigger = "hover" :path="require('@/assets/lottie/upload.json')" />
            </div>
            <div v-show="value" class = "tnm__contactform__form__field__upload__button__value">{{value.name}}</div>
        </template>
        <div class = "tnm__contactform__form__field__error" v-if="validation__message">
            {{ validation__message }}
        </div>
    </div>
</template>
<script>
import { maska } from 'maska'
import LottieAnimation from './LottieAnimation.vue'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
export default {
  components: { LottieAnimation },
    name: "Field",
    directives: { maska },
    data() {
        return {
            value: "",
            date: null,
            validation__message: null,
            months: [
                {value: 1, label: 'January', days: 31},
                {value: 2, label: 'February', days: 28},
                {value: 3, label: 'March', days: 31},
                {value: 4, label: 'April', days: 30},
                {value: 5, label: 'May', days: 31},
                {value: 6, label: 'June', days: 30},
                {value: 7, label: 'July', days: 31},
                {value: 8, label: 'August', days: 31},
                {value: 9, label: 'September', days: 30},
                {value: 10, label: 'October', days: 31},
                {value: 11, label: 'November', days: 30},
                {value: 12, label: 'December', days: 31},
            ],
            valid: false,
            error: false,
        }
    },
    props: [
        'field'
    ],
    computed: {
        countries () {
            const list = countries.getNames('en', { select: 'official' })
            return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
        },
        days() {
            for(var x = 0; x < this.months.length; x++) {
                if(this.date.month.value == this.months[x].value) {
                    return Array.from({length: this.months[x].days}, (value, index) => 1 + index)
                }
            }
            return "";
        },
        years () {
            const year = new Date().getFullYear()
            return Array.from({length: 10}, (value, index) => year + index)
        }
    },
    mounted () {
        if(this.field.type == 'date') {
            let curDate = new Date();
            this.date = {
                month: {
                    value: curDate.getMonth() + 1,
                    label: this.months[curDate.getMonth()].label,
                    days: this.months[curDate.getMonth()].days,
                },
                day: curDate.getDate(),
                year: curDate.getFullYear()
            }
        }
    },
    methods: {
        validate() {
            this.validation__message = null;
            this.valid = true;
            this.error = false;
            if(this.field.validation == 'required') {
                if(this.value == '' || this.value == null) {
                    this.validation__message = 'Please enter a value.';
                    this.valid = false;
                    this.error = true;
                }
            } else if(this.field.validation == 'email') {
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.value)) {
                    this.validation__message = 'Please enter a valid email.';
                    this.valid = false;
                    this.error = true;
                }
            } else if(this.field.validation == 'phone' && this.value != '' && this.value != null) {
                let phone = this.value.replace(/\D/g,'');
                if (!/^\d{10}$/.test(phone)) {
                    this.validation__message = 'Please enter a valid phone number.';
                    this.valid = false;
                    this.error = true;
                }
            }
        },
        checkDate() {
            this.valid = true;
            this.validation__message = null;
            this.error = false;

            let curDate = new Date()
            curDate = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDay())//strip time off
            let checkDate = new Date(this.date.year,this.date.month.value - 1, this.date.day)
            console.log(curDate,checkDate )

            if(checkDate && checkDate > curDate) {
                this.value = this.date.month.value + '/' + this.date.day + '/' + this.date.year;
            } else {
                this.validation__message = 'Please enter a valid date starting today.';
                this.valid = false;
                this.error = true;
            }
        },
        uploadFile() {
            this.valid = true;
            this.validation__message = null;
            this.error = false;
            let file = this.$refs.upload.files[0];

            let size = (file.size/1024/1024);

            console.log(size);

            if(file && size <= 2) {
                this.value = file;
            } else {
                this.validation__message = 'Please upload a file under 2 MB.';
                this.valid = false;
                this.error = true;
            }
        }
    }
}
</script>
