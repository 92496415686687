import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag-next"
import axios from 'axios'
import 'smoothscroll-for-websites'
import cloneDeep from 'lodash'

//globals
import InlineSvg from 'vue-inline-svg'

//state
import { store } from './store'

//routes
import router from './router'

// lottie
import LottieAnimation from "@/components/pieces/LottieAnimation";

import ScrollReveal from 'scrollreveal'

// PrimeVue
import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';

// Fuzzy Circle
import FuzzyCircle from '@/components/pieces/FuzzyCircle';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

//templates
import Generic from "@/components/pages/Generic"
import Home from "@/components/pages/Home"
import WhoWeAre from "@/components/pages/WhoWeAre"
import Games from "@/components/pages/Games"
import GamesDetail from "@/components/pages/GamesDetail"
import News from "@/components/pages/News"
import NewsDetail from "@/components/pages/NewsDetail"
import Careers from "@/components/pages/Careers"
import CareersDetail from "@/components/pages/CareersDetail"
import Contact from "@/components/pages/Contact"
import CircleTest from "@/components/pages/CircleTest"

//GLOBAL CONFIG
let craftApiPreviewToken = ''
if (window.siteConfig?.xCraftLivePreview) {
    craftApiPreviewToken = '?tokenParam=' + window.siteConfig?.xCraftLivePreview + '&token=' + window.siteConfig?.xCraftLivePreviewToken
}
window.config = {
    loadedOnce: false,
    routerInTransition: false,
    localMode: (window.apiroot === '/local-temp/api/' || window.siteConfig?.xCraftLivePreview),
    apiroot: (window.apiroot === '/local-temp/api/') ? '/local-temp/api/' : '/api/',
    apiext: (window.apiroot === '/local-temp/api/') ? '.json' : craftApiPreviewToken,
    json: {
        global: null
    },
    globalScrollReveal: {
        duration: 850,
        distance: '50px',
        viewOffset: {
            bottom: 100
        }
    },
    greenhouse:null
}
axios.get((`${ window.config.apiroot }global${ window.config.apiext }`))
    .then((res) => {
        //GLOBAL CONFIG
        window.config.json.global = res.data

        //DYNAMIC ROUTES
        for (let i = 0; i < res.data.routes.length; i++) {
            let obj = res.data.routes[i]
            let newobj = {
                path: obj.path,
                meta: {
                    title: obj.title,
                    type: obj.type
                },
            }
            switch (obj.type) {
                case 'home':
                    newobj.component = Home
                    break
                case 'who-we-are':
                    newobj.component = WhoWeAre
                    break
                case 'generic':
                    newobj.component = Generic
                    break
                case 'news':
                    newobj.component = News
                    break
                case 'news-detail':
                    newobj.component = NewsDetail
                    break
                case 'careers':
                    newobj.component = Careers
                    break
                case 'contact':
                    newobj.component = Contact
                    break
                case 'games':
                    newobj.component = Games
                    break
                case 'games-detail':
                    newobj.component = GamesDetail
                    break
                case 'circle':
                    newobj.component = CircleTest
                    break
            }
            router.addRoute(newobj)
        }

        //page not found
        let newobj = {
            name: 'page-not-found',
            path: "/:catchAll(.*)",
            //component: PageNotFound,
            meta: {
                title: '404'
            }
        }
        router.addRoute(newobj)

        //BEND FEED ->
        //const greenhouseUrl = 'https://boards-api.greenhouse.io/v1/boards/sonyinteractiveentertainmentplaystation/jobs?content=true'
        const greenhouseUrl = 'https://boards-api.greenhouse.io/v1/boards/thatsnomoonentertainment/jobs?content=true'
        axios.get((greenhouseUrl)).then((res2) => {
            window.config.greenhouse = res2.data

            //careers detail
            if (res2.data) {
                for (let i = 0; i < res2.data.jobs.length; i++) {
                    let tobj = res2.data.jobs[i]
                    let newobj = {
                        path: `/careers/${tobj.id}`,
                        meta: {
                            title: `${tobj.title} - Careers`,
                            type: 'careers-detail',
                            currentJobIndex: i
                        },
                        component: CareersDetail
                    }
                    router.addRoute(newobj)
                }
            }

            //START APP ON SUCCESS
            startApp()
        }).catch(() => {
            //START APP ANYWAY ON FAIL
            startApp()
        })
    }).catch(() => {
    })

function startApp() {
    //LOAD
    const app = createApp(App)



    app.use(router)
    app.use(store)

    app.component('lottie-animation', LottieAnimation)
    app.component('fuzzy-circle', FuzzyCircle)


    //ANALYTICS - in your main.js, below loading your router
    if (window.location.hostname !== "localhost") {
        if (window.config.json.global.config.googleAnalyticsId) {
            app.use(VueGtag, {
                property: {
                    id: window.config.json.global.config.googleAnalyticsId
                }
            })
        }
    }

    //primevue
    app.use(PrimeVue);
    app.component('Dropdown', Dropdown);

    //global inline svg
    app.component('inline-svg', InlineSvg)
    app.mount('#app')

    // Scroll Reveal Directive
    app.directive('sr', {
        mounted(el, binding) {
            setTimeout(function() {
                var sr = ScrollReveal(cloneDeep(window.config.globalScrollReveal))
                var obj = binding.value ? binding.value : {}
                sr.reveal(el, obj, 50)
            },100)
        }
    })

}

