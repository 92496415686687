<template>
    <div class = "tnm__slider__controls" ref = "controls">
        <div class = "tnm__slider__controls__arrows">
            <div class = "tnm__slider__controls__right" ref = "right" @mouseover="triggerButtonEnter('right')" @mouseleave="triggerButtonExit('right')">
                <lottie-animation ref = "right__button" trigger = "function" :path="require('@/assets/lottie/slider-right.json')" />
            </div>
            <div class = "tnm__slider__controls__left" ref = "left" @mouseover="triggerButtonEnter('left')" @mouseleave="triggerButtonExit('left')">
                <lottie-animation ref = "left__button" trigger = "function" :path="require('@/assets/lottie/slider-left.json')" />
            </div>
        </div>
        <div class = "tnm__slider__controls__counter" ref = "counter">
            <span class = "active" ref = "active">{{ activeSlide + 1 }}</span> of {{ numSlides }}
        </div>
    </div>
</template>
<script>
export default {
    name: "Controls",
    props: [ 'activeSlide', 'numSlides' ],
    methods: {
        triggerButtonEnter(btn) {
            if(window.innerWidth > 850) {
                this.$refs[btn].classList.add('active');
                this.$refs[btn + '__button'].playAnimation(1);
            }
        },
        triggerButtonExit(btn) {
            if(window.innerWidth > 850) {
                this.$refs[btn].classList.remove('active');
                this.$refs[btn + '__button'].playAnimation(-1);
            }
        },
    }
}
</script>
