<template>
    <div class="item" @mouseenter="$refs[`btn-itpslides${index}`].playAnimation(1.75)" @mouseleave="$refs[`btn-itpslides${index}`].playAnimation(-1.75)">
        <div class="featured">
            <div class="lines">
                <div class="lines-bottom">
                    <inline-svg :src="require('@/assets/images/games-detail/lines-in-the-press-bottom.svg')"></inline-svg>
                </div>
            </div>
            <div class="bgimg" :style="item.bgImage"></div>
            <div class="lines">
                <div class="lines-top">
                    <inline-svg :src="require('@/assets/images/games-detail/lines-in-the-press-top.svg')"></inline-svg>
                </div>
            </div>

            <div class="caption">
                <div class="caption2">
                    <h3 v-html="item.title"></h3>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="info">
                <div class="info2">
                    <img :src="item.creditImage"/>
                </div>
            </div>
            <div class="btn noselect">
                <lottie-animation :ref="`btn-itpslides${index}`" trigger="function" :path="require('@/assets/lottie/read-button.json')"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "InThePressInner",
    props: {
        item:JSON,
        index: Number
    },
}
</script>
