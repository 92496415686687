<template>
    <section v-if="json" class="page-games-detail">
        <div class="cap">
            <GameMast :json="json.masthead"></GameMast>
            <div class="blocks">
                <div class="cap-margin-padded-2x">
                    <div v-for="(item, index) in json.blocks" :key="'block-wrapper-'+index" :class="['block-wrapper-'+item.type]">
                        <GenericText v-if="item.type==='text'" :json="item.content"></GenericText>
                        <Quote v-else-if="item.type==='quote'" :json="item.content"></Quote>
                        <Image v-else-if="item.type==='image'" :json="item.content"></Image>
                        <VideoGames v-else-if="item.type==='video'" :json="item.content"></VideoGames>
                        <Slides v-else-if="item.type==='slides'" :json="item.content"></Slides>
                        <LatestNews v-else-if="item.type==='latest-news'" :json="item.content"></LatestNews>
                        <InThePress v-else-if="item.type==='in-the-press'" :json="item.content"></InThePress>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import gh from '@/helpers/gh'
import GameMast from '@/components/blocks/generic/GameMast'
import GenericText from '@/components/blocks/generic/GenericText'
import Quote from '@/components/blocks/generic/Quote'
import Image from '@/components/blocks/generic/Image'
import VideoGames from '@/components/blocks/generic/VideoGames'
import Slides from '@/components/blocks/generic/Slides'
import LatestNews from '@/components/blocks/generic/LatestNews'
import InThePress from '@/components/blocks/generic/InThePress'
export default {
    name: "GamesDetail",
    components: {
        GameMast,
        GenericText,
        Quote,
        Image,
        VideoGames,
        Slides,
        LatestNews,
        InThePress
    },
    data () {
        return {
            window: window,
            json: null,
        }
    },
    created () {

    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        slug = slug.replace('games/','')
        let slugHook = (window.config.apiext.charAt(0) === '?') ? '&' : '?'
        axios.get((`${window.config.apiroot}games-detail${window.config.apiext}${slugHook}slug=${slug}`))
            .then((res) => {
                this.json = res.data
            }).catch(() => {
        })

    },
}
</script>
