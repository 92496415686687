<template>
    <div class="top-ctas noselect">
        <div class="strips">
            <div class="strip follow" >
                <div class="follow2" >
                    <div class="follow-states follow-off">
                        <div class="txt">
                            <span>FOLLOW</span>
                        </div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/arrow-right.svg')"></inline-svg>
                        </div>
                    </div>
                    <div class="follow-states follow-on">
                        <div class="follow-on2">
                            <div class="icon on">
                                <inline-svg :src="require('@/assets/images/global/arrow-right.svg')"></inline-svg>
                            </div>
                            <a v-for="(item, index) in this.socialMedia" :key="`ctasocicon-${index}`" :title="item.title" :href="item.link" target="_blank">
                                <span :class="`socicon socicon-${item.sociconId}`"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="strip theme" ref="theme" @click="toggleTheme">
                <div class="icon">
                    <inline-svg :src="require('@/assets/images/global/icon-theme.svg')"></inline-svg>
                </div>
                <div class="txt">
                    <span>THEME</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
export default {
    name: "TopCTAs",
    data() {
        return {
            window:window,
            document:document,
            socialMedia:[]
        }
    },
    mounted() {
        this.socialMedia = this.window.config.json.global.socialMedia
    },
    methods: {
        gotoFollow() {
            //gh.scrollTo('#follow-hook', window)
        },
        toggleTheme() {
            gh.toggleTheme(this.document)
        }
    }
}
</script>
