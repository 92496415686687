<template>
    <div id="related-articles" class="block-article-related" v-sr>
        <div class="rte" v-show="title">
            <h2>RELATED<br>ARTICLES</h2>
            <div class="global-line"></div>
        </div>
        <div class="items">
            <div v-for="(item, index) in json" :key="'block-related-article-'+index" class="item">
                <router-link :to="item.href">
                    <div class="flx">
                        <div class="l">
                            <div class="bgimg-outer">
                                <div class="bgimg" :style="item.bgWithStyle"></div>
                            </div>

                        </div>
                        <div class="r">
                            <div class="r2">
                                <h6 v-html="item.title"></h6>
                                <p v-if="item.author" class="txt-author">{{ item.author }}</p>
                                <p class="txt-date" v-html="gh.dateToMONDDYYYY(item.dateNews,'long','/')"></p>
                            </div>
                        </div>
                    </div>
                    <div class="btn-related" >
                        <div class="bg"></div>
                        <div class="icon">
                            <inline-svg :src="require('@/assets/images/global/btn-related.svg')" />
                        </div>

                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
export default {
    name: "ArticleRelated",
    data () {
        return {
            window: window,
            gh:gh,
        }
    },
    props: {
        json: {
            type: Array,
            required: true,
            default: null,
        },
        title: {
            type: Boolean,
            required: false,
            default: null
        }
    },
    mounted() {

    }
}
</script>
