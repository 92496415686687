<template>
    <div ref = "founder" class = "tnm__founders__founder" @mouseenter="triggerFounderEnter" @mouseleave="triggerFounderExit"  @click="$store.commit('openPopup', {'type':'bio','item':item})">
        <div class = "tnm__founders__founder__image-holder"><div :class="'tnm__founders__founder__image ' + item.id" :style="item.bgWithStyle" ref = "image"></div></div>
        <div ref = "circle" :class="'tnm__founders__founder__circle-image ' + item.id" :style="item.bgWithStyle" ></div>
        <div class = "tnm__founders__founder__overlay">
            <lottie-animation ref = "founder__overlay" trigger = "function" :path="require('@/assets/lottie/founder.json')" />
        </div>
        <h3 class = "default-name">{{ item.name }}</h3>
        <div class = "tnm__founders__founder__info" ref = "info">
            <h3 ref = "name">{{ item.name }}</h3>
            <span ref = "rule" class = "tnm__founders__founder__info__rule tnm__rule"></span>
            <p ref = "title" class = "tnm__founders__founder__info__title">{{ item.title }}</p>
            <div class = "tnm__button tnm__button__gold" ref = "button" :id="item.id" @mouseenter="triggerButtonEnter" @mouseleave="triggerButtonExit">
                <lottie-animation ref = "founder__button" trigger = "function" :path="require('@/assets/lottie/bio-button.json')" />
            </div>
        </div>
    </div>
</template>
<script>
import gsap from '@/assets/js/gsap/gsap-core';
export default {
    name: "Founder",
    props: ['item'],
    mounted() {
            this.tlOff = gsap.timeline({ paused: true });
            this.tlOff
                .fromTo(this.$refs.founder, {zIndex: 500, scale: 1.1, filter: 'none' }, {  duration: 0.2, zIndex: 0, scale: 1, filter: 'grayscale(100%)', ease: "power3"}, 0)
                .fromTo(this.$refs.image, { filter: 'blur(5px)' }, { duration: 0.2, filter: 'none', ease: "power3"}, 0)
                .add(() => { this.$refs.founder__overlay.playAnimation(-1) }, 0)
                .fromTo(this.$refs.circle, { opacity: 1, scale: 1.45, }, {duration: 0.2, scale: 1, opacity: 0, ease: "power3"}, 0.2)
                .fromTo(this.$refs.button, { opacity: 1, y: 0, }, {duration: 0.2, opacity: 0, y: 100, ease: "power3"}, '-=.1')
                .fromTo(this.$refs.title, { opacity: 1, y: 0, }, {duration: 0.2, opacity: 0, y: 100, ease: "power3"}, '-=.1')
                .fromTo(this.$refs.rule, { opacity: 1, y: 0, }, {duration: 0.2, opacity: 0, y: 100, ease: "power3"}, '-=.1')
                .fromTo(this.$refs.name, { opacity: 1, y: 0, }, {duration: 0.2, opacity: 0, y: 100, ease: "power3"}, '-=0')
                .fromTo(this.$refs.info, { opacity: 1, y: 0, pointerEvents: 'all', }, {duration: 0.2, opacity: 0, y: 100, pointerEvents: 'none', ease: "power3"}, '-=.3')
                .add(() => { this.$refs.founder.classList.remove('active'); }, '+=0')

            this.tlOn = gsap.timeline({ paused: true });
            this.tlOn
                .add(() => { this.$refs.founder.classList.add('active'); })
                .fromTo(this.$refs.founder, { zIndex: 0, scale: 1, filter: 'grayscale(100%)' }, {duration: 0.5, zIndex: 500, scale: 1.1, filter: 'none', ease: "power3"}, 0)
                .fromTo(this.$refs.image, { filter: 'none' }, { duration: 0.2, filter: 'blur(5px)', ease: "power3"}, 0)
                .fromTo(this.$refs.circle, { opacity: 0, scale: 1 }, {duration: 0.2, opacity: 1, scale: 1.45, ease: "power3"}, 0.1)
                .fromTo(this.$refs.info, { opacity: 0, y: 100, pointerEvents: 'none', }, {duration: 0.2, opacity: 1, y: 0, ease: "power3", pointerEvents: 'all', }, '-=.3')
                .fromTo(this.$refs.name, { opacity: 0, y: 100, }, {duration: 0.2, opacity: 1, y: 0, ease: "power3"}, '-=0')
                .fromTo(this.$refs.rule, { opacity: 0, y: 100, }, {duration: 0.2, opacity: 1, y: 0, ease: "power3"}, '-=.1')
                .fromTo(this.$refs.title, { opacity: 0, y: 100, }, {duration: 0.2, opacity: 1, y: 0, ease: "power3"}, '-=.1')
                .fromTo(this.$refs.button, { opacity: 0, y: 100, }, {duration: 0.2, opacity: 1, y: 0, ease: "power3"}, '-=.1')
                .add(() => { this.$refs.founder__overlay.setDirection(1); this.$refs.founder__overlay.goToAndPlay(0,true); }, '-=0.8')
    },
    methods: {
        triggerFounderEnter() {
            if(window.innerWidth > 850) {
                this.tlOff.pause();
                this.tlOn.restart();
            }
        },
        triggerFounderExit() {
            if(window.innerWidth > 850) {
                this.tlOn.pause();
                this.tlOff.restart();
            }
        },
        triggerButtonEnter() {
            if(window.innerWidth > 850) {
                this.$refs.button.classList.add('active');
                this.$refs.founder__button.playAnimation(1);
            }
        },
        triggerButtonExit() {
            if(window.innerWidth > 850) {
                this.$refs.founder__button.playAnimation(-1);
                this.$refs.button.classList.remove('active');
            }
        },
    }
}
</script>
