<template>
    <footer id="footer" v-if="json">
        <div class="full-bleed fbl"></div>
        <div class="full-bleed fbr"></div>
        <div class="cap">

            <div class="panels">
                <div class="panel pl">
                    <div class="tt">
                        <router-link
                            to="/"
                        >
                            <inline-svg :src="require('@/assets/images/global/tt-horizontal.svg')" />
                        </router-link>
                    </div>
                    <div v-show="json.footer.esrb.icon" class="bugs">
                        <a v-if="json.footer.esrb.href" :href="json.footer.esrb.href" target="_blank">
                            <img :src="json.footer.esrb.icon" />
                        </a>
                        <img v-else :src="json.footer.esrb.icon" />
                    </div>
                </div>
                <div class="panel pr">
                    <div class="footer-lines">
                        <div class="bgimg"></div>
                    </div>
                    <div class="items">
                        <div class="links">
                            <div class="l1">
                                <div id="follow-hook" class="txt-arrow">
                                    <span class="txt-follow">FOLLOW</span>
                                    <img class="arrow" src="@/assets/images/global/footer-arrow-right.svg" />
                                </div>
                                <div class="socicons">
                                    <a v-for="(item, index) in json.socialMedia" :key="`socicon-${index}`" :title="item.title" :href="item.link" target="_blank">
                                        <span :class="`socicon socicon-${item.sociconId}`"></span>
                                    </a>
                                </div>
                            </div>
                            <div class="l2">
                                <nav>
                                    <ul>
                                        <li
                                            v-for="(item, index) in json.footer.nav"
                                            :key="`footer-nav-${index}`"
                                        >
                                            <Link
                                                :href="item.href"
                                                :target="item.target"
                                                :label="item.label"
                                            />
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="legal">
                            <p v-html="json.footer.legal1"></p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import Link from '@/components/pieces/Link'
export default {
    name: "Footer",
    components: {
        Link
    },
    data () {
        return {
            window: window,
            json: null,
        }
    },
    mounted () {
        this.json = this.window.config.json.global
    },
}
</script>
