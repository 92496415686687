<template>
    <div class = "tnm__slider four" v-sr="{ beforeReveal: triggerAnimations }">
        <div class = "tnm__slider__title" ref = "header">
            <h2 ref = "title">{{ block.title }}</h2>
            <span class = "tnm__slider__title__rule tnm__rule" ref = "rule"></span>
        </div>
        <div class = "tnm__slider__holder" ref = "holder">
            <div class = "tnm__slider__body" ref = "body">
                <div class = "tnm__slider__body__overlay" ref = "overlay">
                    <lottie-animation ref = "ornament" trigger = "function" :path="require('@/assets/lottie/benefits-ornament.json')" />
                </div>
                <div class = "swiper-container" ref = "slider">
                    <div class = "swiper-wrapper">
                        <slide v-for="(item, index) in slideData" :key="`item-${index}`" :item="item" :num="index" :timing="650" :activeSlide="activeSlide" ref = "slide"/>
                    </div>
                </div>
            </div>
            <controls :activeSlide="activeSlide" :numSlides="block.slides.length" ref = "controls" />
        </div>
    </div>
</template>
<script>
import Slide from '@/components/pieces/Slide';
import Controls from '@/components/pieces/slider/Controls';

import { SplitText } from '@/assets/js/gsap/SplitText';
import gsap from '@/assets/js/gsap/gsap-core';
import Swiper, {
    Navigation,
    Pagination,
    Autoplay
} from 'swiper'
import 'swiper/swiper-bundle.min.css'
Swiper.use([Navigation, Pagination, Autoplay])

export default {
    name: "SliderFour",
    components: {
        Slide,
        Controls
    },
    data() {
        return {
            activeSlide: 0,
            slider: null,
            resizeTimeout: null,
        }
    },
    props: [
        'block'
    ],
    created () {
    },
    mounted() {
        this.tl = gsap.timeline();
        this.initSlider();
        this.$nextTick(this.splitText);
    },
    updated() {
        this.$nextTick(this.splitText);
    },
    methods: {
        triggerButtonEnter(btn) {
            if(window.innerWidth > 850) {
                if((btn == 'left' && this.activeSlide !== 0) || (btn == 'right' && this.activeSlide + 1 !== this.block.slides.length)) {
                    this.$refs[btn].classList.add('active');
                    this.$refs[btn + '__button'].playAnimation(1);
                }
            }
        },
        triggerButtonExit(btn) {
            if(window.innerWidth > 850) {
                this.$refs[btn].classList.remove('active');
                this.$refs[btn + '__button'].playAnimation(-1);
            }
        },
        splitText() {
            this.splitTitle = new SplitText(this.$refs.title, { type: 'words' });
        },
        triggerAnimations() {
            this.absolutePositionOrnament(this.slider);
        },
        initSlider() {
            this.slider = new Swiper(this.$refs.slider, {
                loop: true,
                initialSlide: this.block.slides.length * 4, // starting halfway through repeated array data
                speed: 400,
                draggable: false,
                slidesPerView: 1.18,
                preventClicks: true,
                slideToClickedSlide: true,
                cssMode: false,
                spaceBetween: 15,
                direction: 'horizontal',
                swipeAngle: false,
                // allowTouchMove: false,
                // preventClicksPropagation: false,
                navigation: {
                    nextEl: this.$refs.controls.$refs.right,
                    prevEl: this.$refs.controls.$refs.left,
                },
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    850: {
                        slidesPerView: 1.18,
                        spaceBetween: 0,
                    },
                    1150: {
                        slidesPerView: 2,
                    },
                    1500: {
                        slidesPerView: 3,
                    }
                },
            });

            this.slider.on('slideChangeTransitionStart', function() {
                this.$refs.ornament.rewindAnimation();
            }.bind(this))

            this.slider.on('sliderMove', function() {
                this.$refs.ornament.rewindAnimation();
            }.bind(this))

            this.slider.on('slideChangeTransitionEnd', function() {
                this.absolutePositionOrnament(this.slider);
                this.activeSlide = this.getSlideDataIndex(this.slider);
            }.bind(this))

            // fixes issue where transitioning from home section 1 to section 3 resizes nav and causes size of slider to change which requires an update. 
            setTimeout(() => {
                this.slider.update();
                this.absolutePositionOrnament(this.slider);
            }, 350)

            window.addEventListener('resize', this.onResize);

        },
        absolutePositionOrnament(swiper) {
            this.$refs.overlay.style.left = (0 - swiper.slides[swiper.activeIndex].getBoundingClientRect().width*.05) + 'px';
            this.$refs.overlay.style.top = (0 - swiper.slides[swiper.activeIndex].getBoundingClientRect().width*.13) + 'px';
            this.$refs.overlay.style.width = (swiper.slides[swiper.activeIndex].getBoundingClientRect().width*.95) + 'px';

            if(!swiper.slides[swiper.activeIndex].classList.contains('blank')) {
                this.$refs.ornament.playAnimation(1);
            }
        },
        onResize() {
            // update slider after it scales and sometimes there's a 1px line visible
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => {
                this.slider.update();
                this.absolutePositionOrnament(this.slider);
            }, 1000);
        },
        rewindIconAnimation() {
            gsap.to(this.$refs.icon, { opacity: 0, scale: 0, duration: 0.5, ease: 'power3'});
        },
        playIconAnimation() {
            gsap.fromTo(this.$refs.icon, { opacity: 0, scale: 0 }, { duration: 0.5, opacity: 1, scale: 1, ease: 'power3' });
        },
        getSlideDataIndex(swipe){
            var activeIndex = swipe.activeIndex;
            var slidesLen = swipe.slides.length;
            if(swipe.params.loop){
                switch(swipe.activeIndex){
                    case 0:
                        activeIndex = slidesLen-3;
                        break;
                    case slidesLen-1:
                        activeIndex = 0;
                        break;
                    default:
                        --activeIndex;
                }
            }
            return  activeIndex;
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth < 850;
        },
        slideData() {
            // carousel animation jumps when looping, so as a fix we're 
            // just duplicating the array data so it technically never loops 
            var newArray = [];
            var duplications = 8;
            for (var i=0; i < duplications; i++) {
                    newArray = newArray.concat(this.block.slides);             
            }
            // console.log(newArray);
            return newArray;
        }
    },
    beforeUnmount() {
        this.slider.destroy();
        window.removeEventListener('resize', this.onResize);
    },
}
</script>
