<template>
    <div class="landing">

        <div class="landing__inner image" v-if="json.image">
            <lottie-animation trigger="function" :path="require('@/assets/lottie/homepage-circle-ornament-image.json')" class="lottie" ref="lottie" />
            <div class="content">
                <div class="inner">
                    <div>
                        <div class="title">
                            <h3 ref="title" v-html="json.title"></h3>
                            <div class="circles">
                                <!--<img src="/assets/site/images/home/circle.png" alt="">-->

                                <div class = "tnm__fuzzy-circle black" ref = "large__circle">
                                    <fuzzy-circle ref = "header_circle" type="big-black"/>
                                    <div class = "tnm__small-circle top left back gray" ref = "small__circle">
                                        <fuzzy-circle ref = "small_circle" type="small-gray"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text" v-html="json.body"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="landing__inner no-image" v-else>
            <lottie-animation trigger="function" :path="require('@/assets/lottie/homepage-circle-ornament.json')" class="lottie" ref="lottie" />
            <div class="content">
                <div class="inner">
                    <div class="title">
                        <div class="circles">
                            <div class = "tnm__fuzzy-circle black" ref = "large__circle">
                                <fuzzy-circle ref = "header_circle" type="big-black"/>
                                <div class = "tnm__small-circle top left back gray" ref = "small__circle">
                                    <fuzzy-circle ref = "small_circle" type="small-gray"/>
                                </div>
                            </div>
                        </div>
                        <h3 ref="title" v-html="json.title"></h3>
                    </div>
                    <div class="text" v-html="json.body"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    import {
        SplitText
    } from '@/assets/js/gsap/SplitText'
    import gsap, {
        Power3
    } from '@/assets/js/gsap/gsap-core';

    export default {
        props: {
            json: Object
        },
        mounted() {
            this.$store.commit('setLargeSidebar', true);
            this.tl = gsap.timeline({
                delay: 0.4
            });
            this.splitTitleText();
            this.setSvgImageSource();

            if (this.didInitialSiteTransition) {
                this.initAnimation();
            }
        },
        methods: {
            initAnimation() {
                this.tl.from('.circles', {
                        duration: 0.6,
                        opacity: 0,
                        scale: 0,
                    }, 0)
                    .add(() => { this.$refs.header_circle.playAnimation(); }, 0)
                    .add(() => { this.$refs.small_circle.playAnimation(); }, 0.3)
                    .add(() => {
                        this.$refs.lottie.playAnimation();
                    }, 0.2)
                    .from('.title-line-split', {
                        duration: 0.7,
                        x: 100,
                        opacity: 0,
                        ease: Power3.easeOut,
                        stagger: 0.06,
                        letterSpacing: '2rem'
                    }, '-=0.45')
                    .from('.text p', {
                        duration: 0.6,
                        opacity: 0,
                        y: 150,
                        ease: Power3.easeOut,
                        stagger: 0.04
                    }, '-=0.45');
            },
            splitTitleText() {
                new SplitText(this.$refs.title, {
                    type: 'lines',
                    linesClass: 'title-line-split'
                });
            },
            setSvgImageSource() {
                let svgImage = document.querySelector('g.jpg image');
                if (svgImage) {
                    svgImage.setAttribute('href', this.json.image);
                }
            }
        },
        computed: {
            ...mapState({
                didInitialSiteTransition: state => state.didInitialSiteTransition
            }),
        },
        watch: {
            didInitialSiteTransition() {
                this.initAnimation();
            }
        },
    }
</script>