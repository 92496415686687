<template>
    <section v-if="json && currentJob" class="page-careers-detail">

        <div class="header">
            <div class="header__btn"></div>
            <div class="header__img" :style="json.headerImage"></div>
        </div>
        <div class="content">

            <div class="rte">

                <link-content class="back-btn" href="/careers" target="_self">
                    <lottie-animation trigger="hover" :path="require('@/assets/lottie/back-to-careers-btn.json')" class="back-btn-svg" />
                </link-content>

                <div class="game-title">
                    <div class="game-title__inner">
                        <div class="game-title__category">{{ getDepartmentName }}</div>
                        <div class="game-title__position">{{ currentJob.title }}</div>

                        <link-content class="game-title__btn" :href="currentJob.absolute_url" target="_blank">
                            <lottie-animation trigger="hover" :path="require('@/assets/lottie/apply-now-btn.json')" />
                        </link-content>
                    </div>

                    <div class="ornaments">
                        <lottie-animation trigger="function" :path="require('@/assets/lottie/careers-detail-header-bottom.json')" class="decoration__bottom" ref="ornament1" />
                        <lottie-animation trigger="function" :path="require('@/assets/lottie/careers-detail-header-top.json')" class="decoration__top" ref="ornament2" />
                    </div>

                </div>

                <div class="rte__content">
                    <div v-html="decodeHTML(currentJob.content)" ref="rte"></div>

                    <link-content class="apply-now__btn" :href="currentJob.absolute_url" target="_blank">
                        <lottie-animation trigger="hover" :path="require('@/assets/lottie/apply-now-btn.json')" />
                    </link-content>
                </div>

            </div>

            <aside>
                <div>
                    <div class="social-btns">
                        <a :href="gh.getSocialUrl('twitter',window)" target="_blank" v-if="gh.getSocialUrl('twitter',window)">
                            <div class="social-btn social-btn--twitter">
                                <span>follow on Twitter</span>
                                <inline-svg :src="require('@/assets/images/global/twitter.svg')"></inline-svg>
                            </div>
                        </a>
                        <a :href="gh.getSocialUrl('linkedin',window)" target="_blank" v-if="gh.getSocialUrl('linkedin',window)">
                            <div class="social-btn social-btn--linkedin">
                                <span>follow on LinkedIn</span>
                                <inline-svg :src="require('@/assets/images/global/linkedin.svg')"></inline-svg>
                            </div>
                        </a>
                    </div>
                    <div class="similar-jobs" v-if="jobsFromSameCategory.length">
                        <div class="title">Similar Jobs</div>
                        <div class="similar-jobs__list">
                            <link-content class="job" href="/careers/4063632004" target="_self" v-for="item in jobsFromSameCategory" :key="item.title">
                                <div class="job__title">{{ item.title }}</div>
                                <div class="job__department">{{ item.departments[0].name }}</div>
                                <div class="job__white"></div>

                                <svg class="svg-circle" enable-background="new 0 0 173 154" viewBox="0 0 173 154" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none">
                                        <circle cx="94.2" cy="77.1" r="75.9" />
                                        <g stroke="#707070" stroke-width="1.2">
                                            <circle cx="94.2" cy="77.1" r="75.3" />
                                            <path d="m0 77.6h170.6" />
                                        </g>
                                    </g>
                                </svg>

                                <svg class="svg-arrow" height="35.069" viewBox="0 0 29.642 35.069" width="29.642" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none">
                                        <g transform="matrix(0 1 -1 0 29.642 0)">
                                            <path d="m17.534 0 17.535 29.642h-35.069z" />
                                            <path d="m17.53449631 1.96409416-15.78108979 26.67754173h31.56217957zm0-1.96409797 17.53450012 29.6416397h-35.06900024z" fill="#000" />
                                        </g>
                                        <path d="m0 28.564v-28.564" stroke="#000" transform="matrix(0 -1 1 0 0 17.639)" />
                                    </g>
                                </svg>

                                <svg class="svg-btn" height="63.814" viewBox="0 0 44.935 63.814" width="44.935" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m0 0h34.669v63.814h-34.669z" fill="#bea064" />
                                    <g fill="none" stroke="#fff" transform="translate(15.525 17.202)">
                                        <path d="m0 12.221 12.221-12.221 12.22 12.221" transform="matrix(0 1 -1 0 18.784 2.571)" />
                                        <g>
                                            <ellipse cx="14.705" cy="14.705" rx="14.705" ry="14.705" stroke="none" />
                                            <ellipse cx="14.705" cy="14.705" fill="none" rx="14.205" ry="14.205" />
                                        </g>
                                    </g>
                                </svg>

                            </link-content>
                        </div>

                    </div>
                    <div class="related-articles" v-if="json.relatedArticles.length">
                        <div class="title">Related Articles</div>
                        <div class="articles">
                            <link-content class="article" href="#" target="_self" v-for="(item, i) in json.relatedArticles" :key="i">
                                <div class="article__image" :style="item.bgWithStyle">
                                    <svg class="svg-btn" height="63.814" viewBox="0 0 44.935 63.814" width="44.935" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m0 0h34.669v63.814h-34.669z" fill="#bea064" />
                                        <g fill="none" stroke="#fff" transform="translate(15.525 17.202)">
                                            <path d="m0 12.221 12.221-12.221 12.22 12.221" transform="matrix(0 1 -1 0 18.784 2.571)" />
                                            <g>
                                                <ellipse cx="14.705" cy="14.705" rx="14.705" ry="14.705" stroke="none" />
                                                <ellipse cx="14.705" cy="14.705" fill="none" rx="14.205" ry="14.205" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="article__text">
                                    <div class="article__title">{{ item.title }}</div>
                                    <div class="article__author">{{ item.author }}</div>
                                    <div class="article__date">{{ item.date }}</div>
                                </div>
                            </link-content>
                        </div>
                    </div>
                </div>
            </aside>
        </div>

        <VideoWrapper :json="json.video"></VideoWrapper>

        <div class = "slider-holder">
            <Slider :block="sliderBlock"/>
        </div>

    </section>
</template>
<script>
    import axios from 'axios'
    import gh from '@/helpers/gh'
    import LinkContent from '@/components/pieces/LinkContent'
    import VideoWrapper from '@/components/blocks/VideoWrapper'
    import Slider from '@/components/blocks/Slider'
    import gsap, {
        Power2, Power3
    } from '@/assets/js/gsap/gsap-core';
    import {
        SplitText
    } from '@/assets/js/gsap/SplitText'

    export default {
        name: "CareersDetail",
        components: {
            LinkContent,
            VideoWrapper,
            Slider
        },
        data() {
            return {
                json: null,
                currentJob: {},
                gh:gh,
                window:window
            }
        },
        mounted() {

            this.currentJob = window.config.greenhouse.jobs[this.$route.meta.currentJobIndex]
            // console.log(this.currentJob);
            // console.log(window.config.greenhouse);
            console.log(window.config.json.global.socialMedia);

            let slug = this.$route.fullPath
            slug = gh.stripSlashesFrontAndBack(slug)
            let slugHook = (window.config.apiext.charAt(0) === '?') ? '&' : '?'
            axios.get((`${window.config.apiroot}careers-detail${window.config.apiext}${slugHook}slug=${slug}`))
                .then((res) => {
                    this.json = res.data;
                    this.$nextTick(() => {
                        this.searchAndReplaceTitles();
                        this.splitTitleText();
                        this.initAnimation();
                    });

                }).catch((e) => {
                    console.error('Error', slug, e)
                })
        },
        methods: {

            initAnimation() {
                this.tl = gsap.timeline({
                    delay: 0.8
                });
                this.tl.from('.header__img', {
                        duration: 0.8,
                        opacity: 0,
                        x: 400,
                        ease: Power2.easeInOut
                    }, 0.0)
                    .from('.rte', {
                        duration: 0.8,
                        opacity: 0,
                        y: 300,
                        ease: Power2.easeInOut
                    }, '-=0.5')
                    .from('.split', {
                        duration: 0.9,
                        opacity: 0,
                        y: 110,
                        stagger: 0.02,
                        ease: Power3.easeInOut
                    }, '-=0.7')
                    .from('aside', {
                        duration: 0.8,
                        opacity: 0,
                        y: 300,
                        ease: Power2.easeInOut
                    }, '-=0.85')
                    .add(() => {
                        this.$refs.ornament1.playAnimation();
                        this.$refs.ornament2.playAnimation();
                    }, 0.7)


            },
            splitTitleText() {
                new SplitText('.game-title__position', {
                    type: 'words',
                    wordsClass: 'split'
                });
            },
            decodeHTML(html) {
                var txt = document.createElement('textarea');
                txt.innerHTML = html;
                return txt.value;
            },
            searchAndReplaceTitles() {
                this.$refs.rte.innerHTML = this.$refs.rte.innerHTML.replace(/Responsibilities:/g, "<span class='section-title'>Responsibilities:</span>");
                this.$refs.rte.innerHTML = this.$refs.rte.innerHTML.replace(/Requirements &amp; Skills:/g, "<span class='section-title'>Requirements & Skills:</span>");
                this.$refs.rte.innerHTML = this.$refs.rte.innerHTML.replace(/Qualities:/g, "<span class='section-title'>Qualities:</span>");
            }
        },
        computed: {
            jobsFromSameCategory() {
                if (this.currentJob?.departments && window.config.greenhouse.jobs.departments?.length) {
                    return window.config.greenhouse.jobs.filter(job => job.departments[0].name === this.currentJob.departments[0].name && this.currentJob.id !== job.id);
                } else {
                    return []
                }
            },
            getDepartmentName() {
                return (this.currentJob?.departments && this.currentJob.departments.length && this.currentJob.departments[0].name) ? this.currentJob.departments[0].name : ''
            },
            sliderBlock() {
                return {
                    type: "slider-five",
                    slides: this.json.perks
                }
            },
            // TwiterUrl() {

            // },
            LinkedInUrl() {
                let linkedIn = window.config.json.global.socialMedia.filter(item => item.title == "LinkedIn");
                if (linkedIn.length) {
                    return linkedIn.link;
                } else {
                    return '';
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../src/assets/scss/site/pages/careers-detail.scss";
</style>
