<template>
    <div class="block block-slides">
        <div v-if="json" class="block-slides2" v-sr>
            <div v-if="json.title" class="optional-block-subtitle rte no-max-width" >
                <h2 v-html="json.title"></h2>
                <div class="global-line"></div>
            </div>
            <div :id="swiperHookId" class="swiper-hook" v-sr>
                <div class="lines-frame">
                    <div class="lines-frame-l">
                        <div class="slides-lottie" v-sr="{ beforeReveal: triggerLineAnimation, distance: 0 }">
                            <lottie-animation ref="slides_lottie" trigger="function" :path="require('@/assets/lottie/generic-slides.json')" />
                        </div>
                        <div class="swiper-hook2">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide"
                                     v-for="(item, index) in json.slides"
                                     :key="`block-slides-${index}-${Math.random()}`"
                                >
                                    <div class="swiper-slide-inner">
                                        <div class="bgimg" :style="item.bgWithStyle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="navigation-frame noselect">
                        <div class="navigation-frame2">
                            <div class="count">
                                <div class="num-current">{{currentSlide}}</div>
                                <div class="num-total">of {{json.slides.length}}</div>
                            </div>
                            <div class = "slides-btn slides-btn-next" ref = "right" @mouseover="triggerButtonEnter('right')" @mouseleave="triggerButtonExit('right')">
                                <div class="show-desktop">
                                    <lottie-animation ref = "right__button" trigger = "function" :path="require('@/assets/lottie/slider-right.json')" />
                                </div>
                                <div class="show-mobile">
                                    <inline-svg :src="require('@/assets/images/global/button-mobile-next.svg')" />
                                </div>

                            </div>
                            <div class = "slides-btn slides-btn-prev" ref = "left" @mouseover="triggerButtonEnter('left')" @mouseleave="triggerButtonExit('left')">
                                <div class="show-desktop">
                                    <lottie-animation ref = "left__button" trigger = "function" :path="require('@/assets/lottie/slider-left.json')" />
                                </div>
                                <div class="show-mobile">
                                    <inline-svg :src="require('@/assets/images/global/button-mobile-prev.svg')" />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <Caption :caption="currentCaption"/>

            </div>
        </div>
    </div>
</template>

<script>
import Caption from '@/components/blocks/generic/sub/Caption'
import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
import 'swiper/swiper-bundle.min.css'
Swiper.use([Navigation, Pagination, Autoplay])
import gsap, {Power3} from '@/assets/js/gsap'

export default {
    name: "Slides",
    components:{
        Caption
    },
    data () {
        return {
            slider: null,
            swiperHookId: null,
            swiperHookSel: null,
            currentCaption:'',
            currentSlide:1
        }
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
    },
    mounted () {
        this.swiperHookId = 'fs-' + Date.now()
        this.swiperHookSel = '#' + this.swiperHookId + " .swiper-hook2"
        this.init()

        if (this.json.slides.length) {
            this.currentCaption = this.json.slides[0].caption
        }

    },
    methods: {
        init () {
            this.slider = new Swiper(this.swiperHookSel, {
                init: false,
                speed: 500,
                grabCursor: true,
                centeredSlides: true,
                loop: true,
                slidesPerView: 1,
                preventClicks: false,
                preventClicksPropagation: false,
                navigation: {
                    nextEl: '.slides-btn-next',
                    prevEl: '.slides-btn-prev'
                },
                /*
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },

                autoplay: {
                    delay: 5000,
                },
                */
            })

            this.slider.on('slideChangeTransitionStart', function (e) {
                this.currentSlide = e.realIndex+1
                if (this.currentSlide<1) this.currentSlide=1
                this.changeCaption()
            }.bind(this))
            /*
            this.slider.on('slideChangeTransitionEnd', function () {
                this.realIndex = this.slider.realIndex
                let tl = gsap.timeline({});
                tl.fromTo(this.swiperHookSel + ' .captions', 0.3,
                    { opacity: 0 },
                    { opacity: 1, ease: Power3.easeOut },
                    0
                )
            }.bind(this))

             */
            setTimeout(this.sliderInit2, 100)
        },
        sliderInit2 () {
            this.slider.init()
        },
        triggerButtonEnter(btn) {
            this.$refs[btn].classList.add('active');
            this.$refs[btn + '__button'].playAnimation(1);
        },
        triggerButtonExit(btn) {
            this.$refs[btn].classList.remove('active');
            this.$refs[btn + '__button'].playAnimation(-1);
        },
        changeCaption() {
            let tl = gsap.timeline({});
            tl.fromTo('#' + this.swiperHookId + ' .subblock-caption',
                { opacity: 1 },
                { opacity: 0, duration:0.3, ease: Power3.easeIn, onComplete:this.changeCaption2 },
                0
            )
        },
        changeCaption2() {
            const realIndex = this.slider.realIndex
            this.currentCaption = this.json.slides[realIndex].caption
            let tl = gsap.timeline({});
            tl.fromTo('#' + this.swiperHookId + ' .subblock-caption',
                { opacity: 0 },
                { opacity: 1, duration:0.3, ease: Power3.easeIn },
                0
            )

        },
        triggerLineAnimation() {
            this.$refs.slides_lottie.playAnimation(1)
        }
    }
}
</script>
