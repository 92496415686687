<template>
    <div class="studio-news" v-sr="{ beforeReveal: this.playAnimation, distance: 0, opacity: 1 }">

        <div class="title">
            <h3 ref="title">STUDIO NEWS</h3>
        </div>

        <div class="slider">

            <div class="swiper-container swiper-container--news">
                <div class="swiper-wrapper">

                    <div class="swiper-slide" v-for="slide in slideData" :key="slide.title">

                        <link-content class="inner" :href="slide.link.url" :target="slide.link.target">
                            <div class="inner__image" :style="slide.image"></div>
                            <div class="inner__content">
                                <h5>{{ slide.title }}</h5>
                                <p>{{ slide.excerpt }}</p>
                            </div>
                            <div class="read-btn">
                                <lottie-animation trigger="hover" :path="require('@/assets/lottie/read-button.json')" />
                            </div>
                        </link-content>
                    </div>

                    <!-- Extra two blank slides at end so last slide can move to main large slot -->
                    <div class="swiper-slide blank" v-if="!isMobile && json.slides.length > 1"></div>
                    <div class="swiper-slide blank" v-if="!isMobile && json.slides.length > 1"></div>

                </div>
            </div>

            <div class="ornament" ref = "overlay">
                <lottie-animation ref="ornament" trigger="function" :path="require('@/assets/lottie/studio-news-ornament.json')" />
            </div>

        </div>

        <div class="slider__controls" ref="controls">
            <div class="slider__controls__arrows">
                <div class="slider__controls__right" ref="right" @mouseenter="$refs.right__button.playAnimation(1)" @mouseleave="$refs.right__button.playAnimation(-1)">
                    <lottie-animation ref="right__button" trigger="function" :path="require('@/assets/lottie/slider-right.json')" />
                </div>
                <div class="slider__controls__left" ref="left" @mouseenter="$refs.left__button.playAnimation(1)" @mouseleave="$refs.left__button.playAnimation(-1)">
                    <lottie-animation ref="left__button" trigger="function" :path="require('@/assets/lottie/slider-left.json')" />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {
        SplitText
    } from '@/assets/js/gsap/SplitText'
    import gsap, {
        Power3
    } from '@/assets/js/gsap/gsap-core';
    import Swiper, {
        Navigation,
        Pagination,
        Autoplay
    } from 'swiper'
    import 'swiper/swiper-bundle.min.css'
    Swiper.use([Navigation, Pagination, Autoplay])
    import LinkContent from '@/components/pieces/LinkContent'

    export default {
        props: [
            'section',
            'json'
        ],
        components: {
            LinkContent
        },
        data() {
            return {
                activeSlide: 0,
                slider: null,
                resizeTimeout: null,
                // slideIndex: 0,
            }
        },
        mounted() {
            this.$store.commit('setLargeSidebar', false);
            this.tl = gsap.timeline({
                delay: this.isMobile ? 0 : 0.4,
                paused: this.isMobile
            });
            this.splitTitleText();
            this.initSlider();
            this.initAnimation();
        },
        methods: {
            initAnimation() {
                this.tl.from('.studio-news', {
                        opacity: 0,
                        duration: 0
                    })
                    .from(this.$refs.title.querySelectorAll('.title-line-split'), {
                        duration: 0.7,
                        x: 100,
                        opacity: 0,
                        ease: Power3.easeOut,
                        stagger: 0.06,
                        letterSpacing: '2rem'
                    }, 0)
                    .from('.swiper-container--news', {
                        duration: 0.9,
                        opacity: 0,
                        scale: 0.5,
                        ease: Power3.easeInOut
                    }, "-=0.6")
                    .add(() => {
                        this.absolutePositionOrnament(this.slider);
                    }, '-=0.3')
                    .from(this.$refs.right, {
                        duration: 0.7,
                        x: -100,
                        opacity: 0,
                        scale: 1.6,
                        ease: Power3.easeOut
                    }, 0.7)
                    .from(this.$refs.left, {
                        duration: 0.7,
                        x: 100,
                        opacity: 0,
                        scale: 1.6,
                        ease: Power3.easeOut
                    }, 0.7)
            },
            playAnimation() {
                this.tl.play();
            },
            splitTitleText() {
                new SplitText(this.$refs.title, {
                    type: 'lines',
                    linesClass: 'title-line-split'
                });

            },
            initSlider() {
                // starting halfway through repeated array data
                let startingIndex = this.json.slides.length > 2 ? this.json.slides.length * 4 : 0;

                this.slider = new Swiper('.swiper-container--news', {
                    loop: this.json.slides.length > 2,
                    initialSlide: startingIndex,
                    speed: 400,
                    draggable: false,
                    slidesPerView: 1.18,
                    preventClicks: true,
                    slideToClickedSlide: true,
                    cssMode: false,
                    spaceBetween: 15,
                    direction: 'horizontal',
                    swipeAngle: false,
                    navigation: {
                        nextEl: this.$refs.right,
                        prevEl: this.$refs.left,
                    },
                    breakpoints: {
                        850: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        1200: {
                            slidesPerView: 2,
                        },
                        1500: {
                            slidesPerView: 3,
                        }
                    }
                });

                this.slider.on('slideChangeTransitionStart', function() {
                    this.$refs.ornament.rewindAnimation();
                }.bind(this))

                this.slider.on('sliderMove', function() {
                    this.$refs.ornament.rewindAnimation();
                }.bind(this))

                this.slider.on('slideChangeTransitionEnd', function() {
                    this.absolutePositionOrnament(this.slider);
                }.bind(this))

                // fixes issue where transitioning from home section 1 to section 3 resizes nav and causes size of slider to change which requires an update. 
                setTimeout(() => {
                    this.slider.update();
                }, 350)

                window.addEventListener('resize', this.onResize);

            },
            onResize() {
                // update slider after it scales and sometimes there's a 1px line visible
                clearTimeout(this.resizeTimeout);
                this.resizeTimeout = setTimeout(() => {
                    this.slider.update();
                    this.absolutePositionOrnament(this.slider);
                }, 1000);
            },
            absolutePositionOrnament(swiper) {
                this.$refs.overlay.style.left = (0 - swiper.slides[swiper.activeIndex].getBoundingClientRect().width * .137) + 'px';
                this.$refs.overlay.style.top = (0 - swiper.slides[swiper.activeIndex].getBoundingClientRect().width * .132) + 'px';
                this.$refs.overlay.style.width = (swiper.slides[swiper.activeIndex].getBoundingClientRect().width * .65) + 'px';

                if (!swiper.slides[swiper.activeIndex].classList.contains('blank')) {
                    this.$refs.ornament.playAnimation(1);
                }
            }
        },
        computed: {
            isMobile() {
                return window.innerWidth < 850;
            },
            slideData() {
                if (this.json.slides.length > 2) {
                    // // carousel animation jumps when looping, so as a fix we're
                    // // just duplicating the array data so it technically never loops
                    var newArray = [];
                    var duplications = 8;
                    for (var i=0; i < duplications; i++) {
                         newArray = newArray.concat(this.json.slides);
                    }
                    // console.log(newArray);
                    return newArray;
                } else {
                    return this.json.slides;
                }
            }
        },
        beforeUnmount() {
            this.slider.destroy();
            window.removeEventListener('resize', this.onResize);
        },
    }
</script>