<template>
    <router-link v-if="target === '_self'" :to="href">
        <slot></slot>
    </router-link>
    <a v-else :href="href" :target="target">
        <slot></slot>
    </a>
</template>

<script>
export default {
    name: "LinkContent",
    props: {
        href: {
            type: String,
            required: true,
            default: '',
        },
        target: {
            type: String,
            required: false,
            default: '_self',
        },
    },
}
</script>
