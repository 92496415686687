<template>
    <div v-if="block.type=='quote-one'" class = "tnm__quote one" v-sr="{ beforeReveal: triggerAnimations, distance: 0 }">
        <div class = "tnm__quote__text" ref = "text" v-html="block.quote">
        </div>
        <div class = "tnm__quote__animation-holder" ref = "animation">
            <div class = "tnm__quote__animation-holder__animation tnm__fuzzy-circle gray">
                <fuzzy-circle ref = "quote_circle" type="big-gray"/>
                <div class = "tnm__quote__animation-holder__overlay" ref = "overlay">
                    <lottie-animation trigger = "load" :path="require('@/assets/lottie/quote-one.json')" />
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="block.type=='quote-two'" class = "tnm__quote two" v-sr="{ beforeReveal: triggerAnimations, distance: 0 }">
        <div class = "tnm__quote__header" ref = "header">
            <div class = "tnm__quote__animation-holder">
                <div class = "tnm__quote__animation-holder__animation tnm__fuzzy-apostrophe">
                    <div class = "apostrophe" ref = "apostrophe"><img :src="require('@/assets/images/global/quote.png')"/></div>
                    <div class = "tnm__overlay" ref = "overlay">
                        <lottie-animation trigger = "load" :path="require('@/assets/lottie/quote-two.json')" />
                    </div>
                </div>
            </div>
        </div>
        <div class = "tnm__quote__inner" ref = "inner">
            <div class = "tnm__quote__text" v-html="block.quote"></div>
            <div class = "tnm__quote__author">
                <div class = "tnm__quote__author__name">{{ block.author.name }}</div>
                <div class = "tnm__quote__author__title">{{ block.author.title }}</div>
            </div>
            <div class = "tnm__quote__more">
                <link-content href="/careers" target="_self">
                    <div class = "tnm__quote__more__button" ref = "more" @mouseover="triggerButtonEnter('more')" @mouseleave="triggerButtonExit('more')">
                        <lottie-animation trigger = "function" ref = "more__button" :path="require('@/assets/lottie/join-our-team.json')" />
                    </div>
                </link-content>
            </div>
        </div>
    </div>
</template>
<script>
import gsap from '@/assets/js/gsap/gsap-core';
import LinkContent from '@/components/pieces/LinkContent';
export default {
    name: "Quote",
    props: [
        'block'
    ],
    components: {
        LinkContent
    },
    created () {
    },
    mounted () {
    },
    methods: {
        triggerButtonEnter(btn) {
            this.$refs[btn].classList.add('active');
            this.$refs[btn + '__button'].playAnimation(1);
        },
        triggerButtonExit(btn) {
            this.$refs[btn].classList.remove('active');
            this.$refs[btn + '__button'].playAnimation(-1);
        },
        triggerAnimations() {
            var tl = gsap.timeline();
            if(this.block.type == 'quote-one') {
                tl.from(this.$refs.animation, { duration: 0.5, x: 300, opacity: 0, ease: 'power3' }, 0 )
                    .add(() => { this.$refs.quote_circle.playAnimation(); }, 0)
                    .from(this.$refs.overlay, { duration: 0.3, x: 300, opacity: 0, ease: 'power3' }, '-=0.1' )
                    .from(this.$refs.text, { duration: 0.2, x: -100, opacity: 0, ease: 'power3' }, 0 )
            } else if(this.block.type == 'quote-two') {
                tl.from(this.$refs.header, { duration: 0.5, x: -300, opacity: 0, ease: 'power3' }, 0 )
                    .from(this.$refs.apostrophe, { duration: 0.3, opacity: 0, ease: 'power3' }, '-=0.1' )
                    .from(this.$refs.inner, { duration: 0.5, x: 300, opacity: 0, ease: 'power3' }, '-=.1' )
            }
        }
    }
}
</script>