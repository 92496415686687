import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'

const routerHistory = createWebHistory()
const router = createRouter({
    history: routerHistory,
    routes: [], //defined globally in main
    scrollBehavior () {
        //scroll to top on all page changes
        return { top: 0 }
    }
})
router.beforeEach((to, from, next) => {
    //first page load
    if (window.config.routerInTransition) {
        return false
    } else {
        window.config.routerInTransition = true
        if (window.config.loadedOnce === false) {
            window.config.loadedOnce = true
            next()
            store.commit('triggerTransitionOverlay', true)
        } else {
            store.commit('routerNext', next)
            store.commit('triggerTransitionOverlay', true)
        }

        //home start with sidebar open
        // use this to close: this.$store.commit('setLargeSidebar',false)

        store.commit('closeMobileNav')
    }
})
router.afterEach((to) => {
    //set new title after gate
    let metaTitle = to.meta.title
    metaTitle += (metaTitle) ? ` - That's No Moon` : `That's No Moon`
    document.title = metaTitle
    if (to.meta.type==='home') {
        store.commit('setLargeSidebar',true)
    } else {
        store.commit('setLargeSidebar',false)
    }
})
export default router
