<template>
    <div ref = "sliderImage" class="tnm__slider__image-holder" v-if="image">
        <div class = "tnm__slider__image-holder__outer" :style="image" ref = "sliderImage__outer"></div>
        <div class = "tnm__slider__image-holder__inner"><div class = "tnm__slider__image-holder__inner__image" :style="image" ref = "sliderImage__inner"></div></div>
    </div>
</template>
<script>
import gsap from '@/assets/js/gsap/gsap-core';
export default {
    name: "SliderImage",
    props: ['slides', 'activeSlide'],
    data() {
        return {
            state: '',
            image: '',
        }
    },
    mounted() {
        this.image = this.slides[this.activeSlide].bgWithStyle;
        this.animateSlideIn();
    },
    watch: {
        activeSlide: function(newVal) {
            this.image = this.slides[newVal].bgWithStyle;
            this.animateSlideIn();
        }
    },
    methods: {
        animateSlideIn() {
            this.tl = gsap.timeline();
            this.tl
                .fromTo(this.$refs.sliderImage__outer, { filter: 'blur(2px)', rotation: -180, }, { duration: 1.5, filter: 'none', rotation: 0, ease: "power3" }, 0)
                .fromTo(this.$refs.sliderImage__inner, { filter: 'blur(1px)', rotation: -180, }, { duration: 2, filter: 'none', rotation: 0, ease: "power3" }, 0)
        }
    }
}
</script>
