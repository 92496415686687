<template>
    <section v-if="json" class="page-contact global-fonts">
        <div class="cap">
            <template v-for="(block, index) in json.blocks" :key="`block-${index}`">
                <Header v-if="block.type=='header'" :block="block" />
                <Quote v-else-if="block.type=='quote-one' || block.type=='quote-two'" :block="block"/>
                <Slider v-else-if="block.type=='slider-one' || block.type == 'slider-two' || block.type == 'slider-three'  || block.type == 'slider-four'" :block="block"/>
                <Founders v-else-if="block.type=='founders'" :block="block"/>
                <MeetTheTeam v-else-if="block.type=='meet-the-team'" :block="block"/>
                <CareersBlock v-else-if="block.type=='careers'" :block="block"/>
                <ContactForm v-else-if="block.type=='contact-form'" :block="block"/>
            </template>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import Header from '@/components/blocks/Header'
import Quote from '@/components/blocks/Quote'
import Slider from '@/components/blocks/Slider'
import Founders from '@/components/blocks/Founders'
import MeetTheTeam from '@/components/blocks/MeetTheTeam'
import CareersBlock from '@/components/blocks/Careers'
import ContactForm from '@/components/blocks/ContactForm'
export default {
    name: "contact",
    components: {
        Header,
        Quote,
        Slider,
        Founders,
        MeetTheTeam,
        CareersBlock,
        ContactForm,
    },
    data () {
        return {
            window: window,
            json: null,
        }
    },
    created () {

    },
    mounted () {
        axios.get((`${window.config.apiroot}contact${window.config.apiext}`))
            .then((res) => {
                this.json = res.data
                this.reveal()
            }).catch(() => {
        })

    },
    methods: {
        reveal () {

        },
    }
}
</script>
