<template>
    <section class="page-circle-test">
        <div class="cap">
            <div class="cap-padded">
              <div class="rte">
                  <FuzzyCircle/>
              </div>
            </div>
        </div>
    </section>
</template>
<script>
import FuzzyCircle from '@/components/pieces/FuzzyCircle'
export default {
    name: "CircleTest",
    components: {
        FuzzyCircle
    },
    data () {
        return {
            window: window,
        }
    },
    created () {

    },
    mounted () {

    },
    methods: {
        reveal () {

        },
    }
}
</script>
