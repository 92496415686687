<template>
    <div id="sidebar">
        <div class="inner">
            <div class="tt" :style="{ 'pointer-events': $route.path == '/' ? 'none' : 'all' }">
                <router-link to="/">
                    <inline-svg :src="require('@/assets/images/global/tt-vertical.svg')"></inline-svg>
                </router-link>
            </div>
            <MainNav />
        </div>
    </div>
</template>

<script>
    import MainNav from '@/components/framework/sub/MainNav'
    export default {
        name: "Sidebar",
        components: {
            MainNav
        },
    }
</script>
